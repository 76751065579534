import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {formatMonthYear} from '../../utils/dateUtils';
import {numericStyle, roundNumber, valueOrNA} from "../../utils/numberUtils";
import {PRESENTATION_TYPES} from "../../utils/constants";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

// ------------------------------------ Constants ----------------------------------
export const FOB_LOAD_FILTER_LIST_REQUEST = 'FOB_LOAD_FILTER_LIST_REQUEST';
export const FOB_LOAD_FILTER_LIST_SUCCESS = 'FOB_LOAD_FILTER_LIST_SUCCESS';
export const FOB_LOAD_FILTER_LIST_FAILURE = 'FOB_LOAD_FILTER_LIST_FAILURE';

export const FOB_LOAD_CHART_DATA_REQUEST = 'FOB_LOAD_CHART_DATA_REQUEST';
export const FOB_LOAD_CHART_DATA_SUCCESS = 'FOB_LOAD_CHART_DATA_SUCCESS';
export const FOB_LOAD_CHART_DATA_FAILURE = 'FOB_LOAD_CHART_DATA_FAILURE';

export const FOB_LOAD_SETTINGS_REQUEST = 'FOB_LOAD_SETTINGS_REQUEST';
export const FOB_LOAD_SETTINGS_SUCCESS = 'FOB_LOAD_SETTINGS_SUCCESS';
export const FOB_LOAD_SETTINGS_FAILURE = 'FOB_LOAD_SETTINGS_FAILURE';

export const FOB_LOAD_ARGUS_LEGEND_REQUEST = 'FOB_LOAD_ARGUS_LEGEND_REQUEST';
export const FOB_LOAD_ARGUS_LEGEND_SUCCESS = 'FOB_LOAD_ARGUS_LEGEND_SUCCESS';
export const FOB_LOAD_ARGUS_LEGEND_FAILURE = 'FOB_LOAD_ARGUS_LEGEND_FAILURE';

export const FOB_LOAD_TRANSLATION_DATA_REQUEST = 'FOB_LOAD_TRANSLATION_DATA_REQUEST';
export const FOB_LOAD_TRANSLATION_DATA_SUCCESS = 'FOB_LOAD_TRANSLATION_DATA_SUCCESS';
export const FOB_LOAD_TRANSLATION_DATA_FAILURE = 'FOB_LOAD_TRANSLATION_DATA_FAILURE';

export const FOB_CHANGE_FILTER_VALUE = 'FOB_CHANGE_FILTER_VALUE';
export const FOB_CHANGE_CHART_VIEW = 'FOB_CHANGE_CHART_VIEW';

const LOAD_NO_DATA_TEXT_REQUEST = 'FOB_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'FOB_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'FOB_LOAD_NO_DATA_TEXT_FAILURE';

const endpointMapping = {
    filters: {
        years: '/api/prices/yearsList',
        products: '/api/prices/subsidizedProductsList',
    },
    chartData: {
        chart: '/api/prices/comparisonSeries'
    },
    settings: '/api/configuration/commercialVsSubsidizedOverTimeChart',
    translations:'/api/dashboardTranslation/all',
    noDataText: '/api/settings/noData',

}

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
        apiConnector.getData(endpointMapping.noDataText).then(data => {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
        }).catch(function(err) {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
        })
    }
}

export const loadTranslationsData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_LOAD_TRANSLATION_DATA_REQUEST, filter });
        apiConnector.getData(endpointMapping.translations).then(data => {
            dispatch({ 'type': FOB_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': FOB_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
        })
    }
}

export const loadFilterData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_LOAD_FILTER_LIST_REQUEST, filter });
        const lang = getState().getIn(['intl', 'locale']);
        let qs = `selectedLanguage=${lang}`;
        if (filter === 'defaults') {
            qs = `${qs}&chartView=true`;
        }
        if (filter === 'defaults_table') {
            qs = `${qs}&chartView=false`;
        }
        apiConnector.getData(`${endpointMapping.filters[filter]}?${qs}`).then(data => {
            dispatch({ 'type': FOB_LOAD_FILTER_LIST_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': FOB_LOAD_FILTER_LIST_FAILURE, err, filter });
        })
    }
}

export const loadFilterDataIfNotLoaded = (filter) => {
    return (dispatch, getState) => {
        if(!getState().getIn(['fobPrices', 'filtersData', filter, 'loaded'])) {
            dispatch(loadFilterData(filter));
        }
    }
}

export const loadChartData = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_LOAD_CHART_DATA_REQUEST });
        const params = getState().getIn(['fobPrices', 'filtersSelections']).toJS();
        params.lang = getState().getIn(['intl', 'locale']);
        const chartView = getState().getIn(['fobPrices', 'chartView']);
        if (chartView) {
            params.productOriginsSelected = params.productOriginsSelectedChart;
        } else {
            params.productOriginsSelected = params.productOriginsSelectedTable;
        }
        apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
            dispatch({ 'type': FOB_LOAD_CHART_DATA_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': FOB_LOAD_CHART_DATA_FAILURE, err });
        })
    }
}

export const loadDefaultSettings = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_LOAD_SETTINGS_REQUEST });
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
            dispatch(setDefaultFilters(data));
            dispatch({ 'type': FOB_LOAD_SETTINGS_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': FOB_LOAD_SETTINGS_FAILURE, err });
        })
    }
}

export const resetFilters = (chart) => {
    return (dispatch, getState) => {
        dispatch(setDefaultFilters());
        dispatch(loadChartData());
    }
}

const setDefaultFilters = (settings) => {
    return (dispatch, getState) => {
        const data = settings || getState().getIn(['fobPrices', 'defaultSettings', 'data']);
        const fobProductsTable = data.fobProductsTable;
        const fobProductsChart = data.fobProducts;
        dispatch({ 'type': FOB_LOAD_FILTER_LIST_SUCCESS, data: fobProductsTable, filter: 'productsTable' });
        dispatch({ 'type': FOB_LOAD_FILTER_LIST_SUCCESS, data: fobProductsChart, filter: 'productsChart' });
        const defaultProductsChart = getState().getIn(['fobPrices', 'filtersData', 'defaults', 'data', 'productOriginsSelected']);
        dispatch(changeFilterValue('productOriginsSelectedChart', defaultProductsChart, false));
        const defaultProductsTable = getState().getIn(['fobPrices', 'filtersData', 'defaults_table', 'data', 'productOriginsSelected']);
        dispatch(changeFilterValue('productOriginsSelectedTable', defaultProductsTable, false));
        dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'fobPrices', 'unit', 'dates', 'currencyCode');
    }
}

export const loadArgusLegend = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_LOAD_ARGUS_LEGEND_REQUEST });
        apiConnector.getData(endpointMapping.argusLegend).then(data => {
            dispatch({ 'type': FOB_LOAD_ARGUS_LEGEND_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': FOB_LOAD_ARGUS_LEGEND_FAILURE, err });
        })
    }
}


export const changeFilterValue = (filter, value, reload) => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_CHANGE_FILTER_VALUE, filter, value });
        if (reload) {
            dispatch(loadChartData());
        }
    }
}

export const setChartView = (chartView) => {
    return (dispatch, getState) => {
        dispatch({ 'type': FOB_CHANGE_CHART_VIEW, chartView });
        dispatch(loadChartData());
    }
}

export const getFiltersForPrint = () => {
    return (dispatch, getState) => {
        const selectedLanguage = getState().getIn(['fobPrices', 'selectedLanguage']);
        const translationData = getState().getIn(['fobPrices', 'translationData']).toJS().data;
        const fobFilters = getState().getIn(['fobPrices', 'filtersSelections']);
        return [
            {name: getText('general:unit', {selectedLanguage, translationData}), options: [fobFilters.get('unit')]},
        ];
    }
}

export const createExportData = () => {
    return (dispatch, getState) => {
        const fobData = getState().getIn(['fobPrices', 'chartData']);
        const products = getState().getIn(['fobPrices', 'filtersData', 'products']);
        const fobFilters = getState().getIn(['fobPrices', 'filtersSelections']);
        const defaultSettings = getState().getIn(['fobPrices', 'defaultSettings', 'data']);
        const selectedLanguage = getState().getIn(['fobPrices', 'selectedLanguage']);
        const translationData = getState().getIn(['fobPrices', 'translationData']).toJS().data;
        const datesList = [];
        const startDate = fobFilters.get('dates')[0] ? new Date(fobFilters.get('dates')[0].replace(/-/g, '/')) : null; //replace hyphens to avoid issue with day offset due timezone
        const endDate = fobFilters.get('dates')[1] ? new Date(fobFilters.get('dates')[1].replace(/-/g, '/')) : null;
        datesList.push(`${getText('filter:from', {selectedLanguage, translationData})} ${formatMonthYear(startDate)}`);
        datesList.push(`${getText('filter:to', {selectedLanguage, translationData})} ${formatMonthYear(endDate)}`);
        const productsList = products.get('data').filter(i => fobFilters.get('productOriginsSelected').indexOf(i.id) !== -1).map(i => i.name);
        const expFilters = [
            {name: getText('general:dateRange', {selectedLanguage, translationData}), values: datesList.join(', ')},
            {name: getText('general:products', {selectedLanguage, translationData}), values: productsList.length === products.length ? '' : productsList.join(', ')},
        ];
        const columns = [
            {headerTitle: getText('availability:date', {selectedLanguage, translationData}), key: 'date', width: 25},
            {headerTitle: getText('general:productHSCode', {selectedLanguage, translationData}), key: 'hsCode', width: 25},
            {headerTitle: getText('general:product', {selectedLanguage, translationData}), key: 'fertilizer', width: 55},
            {headerTitle: `${getText('general:InternationalPrice', {selectedLanguage, translationData})} - ${fobFilters.get('unit')}`, key: 'price', width: 30},
            {headerTitle: getText('general:monthlyPercentChange', {selectedLanguage, translationData}), key: 'changePercent', width: 55},
            {headerTitle: getText('general:yearlyPercentChange', {selectedLanguage, translationData}), key: 'yearlyChangePercent', width: 55},
        ];
        const rows = [];
        fobData.get('data').forEach(p => {
            p.data.forEach((d, key, arr) => {
                rows.push({
                    date: d.x,
                    changePercent: valueOrNA(d.changePercent),
                    yearlyChangePercent: Object.is(arr.length - 1, key) ?
                        valueOrNA(p.changePercent) :
                        "N/A",
                    hsCode: p.seriesInfo.hsCode && p.seriesInfo.hsCode !== 'null' ? p.seriesInfo.hsCode : '',
                    fertilizer: p.id,
                    price: valueOrNA(roundNumber(d.y)),
                    cellsStyles: {
                        date: {
                            alignment: {vertical: 'middle', horizontal: 'center'}
                        },
                        price: numericStyle,
                        hsCode: {
                            alignment: {vertical: 'middle', horizontal: 'left'}
                        }
                    }
                });
            })
        })
        return {
            'title': defaultSettings.customChartName || 'FOB Prices', filters: expFilters, columns, rows,
            source: defaultSettings.source,
            contactInfo: 'For full price history contact fertilizer@argusmedia.com'
        }
    }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
    [FOB_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
        const {data, filter} = action;
        return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
    },
    [FOB_CHANGE_CHART_VIEW]: (state, action) => {
        const {chartView} = action;
        return state.setIn(['chartView'], chartView);
    },
    [FOB_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['loadingError'], err);
    },
    [FOB_LOAD_CHART_DATA_REQUEST]: (state, action) => {
        return state.setIn(['chartData', 'loading'], true);
    },
    [FOB_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['chartData', 'data'], data)
            .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
    },
    [FOB_LOAD_CHART_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
    },
    [FOB_LOAD_SETTINGS_REQUEST]: (state, action) => {
        return state.setIn(['defaultSettings', 'loading'], true);
    },
    [FOB_LOAD_SETTINGS_SUCCESS]: (state, action) => {
        const {data} = action;
        if(data.defaultPresentationType) {
            state = state.setIn(['chartView'], data.defaultPresentationType.code === PRESENTATION_TYPES.LINE_CHART);
        }
        return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
    },
    [FOB_LOAD_SETTINGS_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
    },
    [FOB_LOAD_ARGUS_LEGEND_REQUEST]: (state, action) => {
        return state.setIn(['argusLegend', 'loading'], true);
    },
    [FOB_LOAD_ARGUS_LEGEND_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['argusLegend', 'loaded'], true).setIn(['argusLegend', 'data'], data);
    },
    [FOB_LOAD_ARGUS_LEGEND_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['argusLegend', 'loading'], false).setIn(['loadingError'], err);
    },
    [FOB_CHANGE_FILTER_VALUE]: (state, action) => {
        const {filter, value} = action;
        return state.setIn(['filtersSelections', filter], value);
    },
    [FOB_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
        return state.setIn(['translationData', 'loading'], true);
    },
    [FOB_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['translationData', 'data'], data)
            .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
    },
    [FOB_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
    },
    [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
        return state.setIn(['noDataText', 'loading'], true);
    },
    [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['noDataText', 'data'], data)
          .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
    },
    [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
    },
};


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
    chartView: false,
    filtersData: {
        defaults: {
            loaded: false, data: []
        },
        dates: {
            loaded: false, data: []
        },
        productsChart: {
            loaded: false, data: []
        },
        productsTable: {
            loaded: false, data: []
        }
    },
    filtersSelections: {
        productOriginsSelectedTable: [],
        productOriginsSelectedChart: [],
        productOriginsSelected: [],
        dates: [],
        unit: '',
        currencyCode: ''
    },
    defaultSettings: {
        loaded: false,
        loading: false,
        data: []
    },
    noDataText: {
        loaded: false,
        loading: false,
        data: {}
    },
    translationData: {
        loaded: false,
        loading: false,
        data: {
            defaultLanguage: 'en',
            translations: {}
        }
    },
    argusLegend: {
        loaded: false,
        loading: false,
        data: {}
    },
    chartData: {
        loaded: false,
        loading: false,
        data: [], convertedData: {}
    }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
}
