import {Container, Flag, Menu} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {MenuConsumer, MenuProvider, utils} from "@devgateway/wp-react-lib";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
//import {API_ROOT_URL} from "../utils/apiUtils";


const getPath = (menu, match) => {
    let path = [];
    menu.items.forEach(item => {
        if (item.child_items) {
            item.child_items.forEach(ch => {
                if (ch.slug == match.params.slug) {
                    path.push(item)
                    path.push(ch)
                }
            })
        } else if (item.slug == match.params.slug && item.url != '/') {
            path.push(item)
        }


    })
    return path
}


const LangSelector = withRouter(injectIntl(({history, match, intl}) => {
    const [expanded, setExpanded] = useState(false)
    const changeLang = (lang) => {
        history.push('/' + lang + history.location.pathname.slice(3) + history.location.search);
        setExpanded(false)
    }
    const API_ROOT_URL = (document.location.hostname === 'localhost') ? 'http://localhost:8090' : process.env.REACT_APP_ADMIN_URL;

    return <div className="lang-selector">
        <div className="selected" onClick={c => setExpanded(!expanded)}><img src={`${API_ROOT_URL}/api/language/flag/${intl.locale}`}/></div>
        {expanded && <div className="options">
            {intl.locale != 'en' && <div className="option" onClick={c => changeLang('en')}>
                <img src={`${API_ROOT_URL}/api/language/flag/en`}/>
            </div>}
            {intl.locale != 'fr' && <div className="option" onClick={c => changeLang('fr')}>
                <img src={`${API_ROOT_URL}/api/language/flag/fr`}/>
            </div>}
            {intl.locale != 'pt' && <div className="option" onClick={c => changeLang('pt')}>
                <img src={`${API_ROOT_URL}/api/language/flag/pt`}/>
            </div>}
        </div>}
    </div>
}))

const BreadCrumbs = withRouter(injectIntl(({menu, match, intl}) => {

    let path = getPath(menu, match)
    return <React.Fragment>
        <a href={"#"}> Home </a>
        {path.map(i => !i.child_items ? <a className={i.slug == match.params.slug ? 'active' : ''}
                                           href={utils.replaceLink(i.url, intl.locale)}> {i.post_title}</a> :
            <span>{i.post_title} </span>)}
    </React.Fragment>

}))


const MyMenuItems = injectIntl(withRouter(({
                                               withIcons, active,
                                               menu, match, className,
                                               onSetSelected, selected,
                                               menuHover, onMenuHover,
                                               intl: {locale}
                                           }) => {

    useEffect((e) => {
        if (!selected) {
            const pathSelected = getPath(menu, match)
            const items = pathSelected.filter(i => i.menu_item_parent == 0)
            if (items) {
                onSetSelected(items[0])
            }
        }
    }, [match, menu, onSetSelected, selected])


    return menu && <ul className={className}>
        {menu.items.map(i => (
            <li onMouseOver={e => onMenuHover(i)}
                className={`divided ${i.child_items ? 'has-child-items' : ''}
                 ${selected && selected.ID == i.ID ? 'selected' : ''}  ${active == i.slug ? "active" : ""}`}>
                {i.child_items ?
                    <>
                        <a href={utils.replaceLink(i.url, locale)}>{i.title} <a className="menu-arrow">+</a></a>
                        {menuHover === i && <span>
                            <MyMenuItems active={active} onSetSelected={onSetSelected} selected={selected}
                                         onMenuHover={onMenuHover} menuHover={menuHover} className={"sub-menu"}
                                         menu={{items: i.child_items}}>}</MyMenuItems>
                        </span>}
                    </>
                    :
                    <a href={utils.replaceLink(i.url, locale)}>{i.title}</a>
                }
            </li>))}
    </ul>
}))

const Header = ({intl: {locale} , match, history}) => {

    const [selected, setSelected] = useState()
    const [menuHover, setMenuHover] = useState()
    const {slug} = match.params
    const logoUrl = process.env.REACT_APP_USE_HASH_LINKS ? `/#/${locale}` : `/${locale}`
    return <React.Fragment>


        <MenuProvider slug={"main"}>
            <Container fluid={true} className="header">
                <Container fluid={true} className={"background"}>

                    <Menu className={"branding"} text>
                        <Menu.Item>
                            <a href={logoUrl}><img className="brand logo" size="large" src={process.env.PUBLIC_URL + '/afo-logo.png'}/></a>
                        </Menu.Item>
                        <Menu.Menu className={"pages"}>
                            <MenuConsumer className="menu-top-navigation-container">
                            <label className="hamburger" for="nav-toggle"></label>
                            <input id="nav-toggle" type="checkbox" className="hidden-checkbox" />
                                <MyMenuItems className={"main-menu"}
                                             active={slug} selected={selected} onSetSelected={setSelected}
                                             menuHover={menuHover} onMenuHover={setMenuHover}/>
                            </MenuConsumer>
                        </Menu.Menu>

                        <Menu.Item className="lang-menu">
                            <LangSelector/>
                        </Menu.Item>
                    </Menu>

                </Container>

                {/*<Container fluid={true} className={"child"}>
                    {selected && selected.child_items && <Menu fluid text>
                        <MyMenuItems active={slug} withIcons onSetSelected={e => null}
                                     menu={{items: selected.child_items}}>}</MyMenuItems>

                    </Menu>}
                </Container>*/}
            </Container>

            {/*<Container className={"url breadcrumbs"}>
                <MenuConsumer>
                    <BreadCrumbs></BreadCrumbs>
                </MenuConsumer>
            </Container>*/}
        </MenuProvider>
    </React.Fragment>

}


export default injectIntl(withRouter(Header))