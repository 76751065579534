import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {formatDate, formatMonthYear} from '../../utils/dateUtils';
import {numericStyle, roundNumber} from "../../utils/numberUtils";
import {dispatchFilterDefaults} from "../../utils/chartUtils";
import {NRP_LOAD_FILTER_LIST_SUCCESS} from "../nationalRetailPrices/module";

// ------------------------------------ Constants ----------------------------------
export const SPOT_LOAD_FILTER_LIST_REQUEST = 'SPOT_LOAD_FILTER_LIST_REQUEST';
export const SPOT_LOAD_FILTER_LIST_SUCCESS = 'SPOT_LOAD_FILTER_LIST_SUCCESS';
export const SPOT_LOAD_FILTER_LIST_FAILURE = 'SPOT_LOAD_FILTER_LIST_FAILURE';

export const SPOT_LOAD_CHART_DATA_REQUEST = 'SPOT_LOAD_CHART_DATA_REQUEST';
export const SPOT_LOAD_CHART_DATA_SUCCESS = 'SPOT_LOAD_CHART_DATA_SUCCESS';
export const SPOT_LOAD_CHART_DATA_FAILURE = 'SPOT_LOAD_CHART_DATA_FAILURE';

export const SPOT_LOAD_SETTINGS_REQUEST = 'SPOT_LOAD_SETTINGS_REQUEST';
export const SPOT_LOAD_SETTINGS_SUCCESS = 'SPOT_LOAD_SETTINGS_SUCCESS';
export const SPOT_LOAD_SETTINGS_FAILURE = 'SPOT_LOAD_SETTINGS_FAILURE';

export const SPOT_LOAD_TRANSLATION_DATA_REQUEST = 'SPOT_LOAD_TRANSLATION_DATA_REQUEST';
export const SPOT_LOAD_TRANSLATION_DATA_SUCCESS = 'SPOT_LOAD_TRANSLATION_DATA_SUCCESS';
export const SPOT_LOAD_TRANSLATION_DATA_FAILURE = 'SPOT_LOAD_TRANSLATION_DATA_FAILURE';

export const SPOT_CHANGE_FILTER_VALUE = 'SPOT_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'SPOT_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'SPOT_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'SPOT_LOAD_NO_DATA_TEXT_FAILURE';

const endpointMapping = {
    filters: {
        dates: '/api/prices/dates/all',
        regions: '/api/region/nonNimasaList',
        countries: '/api/prices/countries',
        defaults: '/api/filtersDefaults/prices/singleProductByCountry'
    },
    translations:'/api/dashboardTranslation/all',
    chartData: '/api/prices/singleProductByCountry',
    noDataText: '/api/settings/noData',
    settings: '/api/configuration/nationalAvgUreaPriceOverTime',
}

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
        apiConnector.getData(endpointMapping.noDataText).then(data => {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
        }).catch(function(err) {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
        })
    }
}

export const loadTranslationsData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': SPOT_LOAD_TRANSLATION_DATA_REQUEST, filter });
        apiConnector.getData(endpointMapping.translations).then(data => {
            dispatch({ 'type': SPOT_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': SPOT_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
        })
    }
}

export const loadFilterDataIfNotLoaded = (filter) => {
    return (dispatch, getState) => {
         if(!getState().getIn(['singleProductPriceOverTime', 'filtersData', filter, 'loaded'])) {
             dispatch(loadFilterData(filter));
         }
    }
}

export const loadFilterData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': SPOT_LOAD_FILTER_LIST_REQUEST, filter });
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
            dispatch({ 'type': SPOT_LOAD_FILTER_LIST_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': SPOT_LOAD_FILTER_LIST_FAILURE, err, filter });
        })
    }
}

export const loadChartData = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': SPOT_LOAD_CHART_DATA_REQUEST });
        const params = getState().getIn(['singleProductPriceOverTime', 'filtersSelections']).toJS();
        params.lang = getState().getIn(['intl', 'locale']);
        apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
            dispatch({ 'type': SPOT_LOAD_CHART_DATA_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': SPOT_LOAD_CHART_DATA_FAILURE, err });
        })
    }
}

export const loadDefaultSettings = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': SPOT_LOAD_SETTINGS_REQUEST });
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
            const fobProducts = data.fobProducts;
            dispatch({ 'type': SPOT_LOAD_FILTER_LIST_SUCCESS, data: fobProducts, filter: 'fobProducts' });
            dispatch(setDefaultFilters(data));
            dispatch({ 'type': SPOT_LOAD_SETTINGS_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': SPOT_LOAD_SETTINGS_FAILURE, err });
        });
    }
}

export const resetFilters = (chart) => {
    return (dispatch, getState) => {
        dispatch(setDefaultFilters());
        dispatch(loadChartData());
    }
}

const setDefaultFilters = (settings) => {
    return (dispatch, getState) => {
        const data = settings || getState().getIn(['singleProductPriceOverTime', 'defaultSettings', 'data']);
        const regionType = data.regionType;
        const fullCountryList = getState().getIn(['singleProductPriceOverTime', 'filtersData', 'countries', 'data']);
        const countries = fullCountryList.filter(c => c.regions.find(r => r.type.id === regionType.id) !== undefined)
            .map(c => ({...c, region: c.regions ? c.regions.find(r => r.type.id === regionType.id) : null }));
        dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'singleProductPriceOverTime',  'currencyCode', 'countriesSelected', 'dates');
        const productOriginsSelected = getState().getIn(['singleProductPriceOverTime', 'filtersData', 'fobProducts', 'data']);
        dispatch(changeFilterValue('productOriginsSelected', productOriginsSelected.map(p => p.id), false));
        dispatch(changeFilterValue('unit', data.defaultChartUnit.value, false));
        const regions = getState().getIn(['singleProductPriceOverTime', 'filtersData', 'regions', 'data']);
        //update country list with the region of the region type given in chart settings:
        const regionData = regions.filter(r => r.type.id === regionType.id);
        dispatch({'type': SPOT_LOAD_FILTER_LIST_SUCCESS, data: regionData, filter: 'regions'});//clean regions list with setting
        dispatch({'type': SPOT_LOAD_FILTER_LIST_SUCCESS, data: countries, filter: 'countries'});
    }
}

export const changeFilterValue = (filter, value, reload) => {
    return (dispatch, getState) => {
        dispatch({ 'type': SPOT_CHANGE_FILTER_VALUE, filter, value });
        if (reload) {
            dispatch(loadChartData());
        }
    }
}


export const getFiltersForPrint = () => {
    return (dispatch, getState) => {
        const filters = getState().getIn(['singleProductPriceOverTime', 'filtersSelections']);
        const selectedLanguage = getState().getIn(['singleProductPriceOverTime', 'selectedLanguage']);
        const translationData = getState().getIn(['singleProductPriceOverTime', 'translationData']).toJS().data;
        return [
        ];
    }
}

export const createExportData = () => {
    return (dispatch, getState) => {
        const data = getState().getIn(['singleProductPriceOverTime', 'chartData']);
        const filters = getState().getIn(['singleProductPriceOverTime', 'filtersSelections']);
        const defaultSettings = getState().getIn(['singleProductPriceOverTime', 'defaultSettings', 'data']);
        const selectedLanguage = getState().getIn(['singleProductPriceOverTime', 'selectedLanguage']);
        const translationData = getState().getIn(['singleProductPriceOverTime', 'translationData']).toJS().data;
        const datesList = [];
        const startDate = filters.get('dates')[0] ? new Date(filters.get('dates')[0].replace(/-/g, '/')) : null; //replace hyphens to avoid issue with day offset due timezone
        const endDate = filters.get('dates')[1] ? new Date(filters.get('dates')[1].replace(/-/g, '/')) : null;
        datesList.push(`${getText('filter:from', {selectedLanguage, translationData})} ${formatMonthYear(startDate)}`);
        datesList.push(`${getText('filter:to', {selectedLanguage, translationData})} ${formatMonthYear(endDate)}`);
        const countries = getState().getIn(['singleProductPriceOverTime', 'filtersData', 'countries']);
        const countryList = countries.get('data').filter(i => filters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
        const expFilters = [
            {name: getText('general:dateRange', {selectedLanguage, translationData}), values: datesList.join(', ')},
            {name: getText('general:countries', {selectedLanguage, translationData}), values: countryList.join(', ')},
        ];
        const columns = [
            {headerTitle: getText('general:date', {selectedLanguage, translationData}), key: 'date', width: 25},
            {headerTitle: getText('plantDirectory:country', {selectedLanguage, translationData}), key: 'country', width: 40},
            {headerTitle: `${getText('general:openMarketRetail', {selectedLanguage, translationData})} - ${filters.get('unit')}`, key: 'price', width: 30}
        ];
        const rows = [];
        data.get('data').forEach(p => {
            p.data.forEach(d => {
                if (d.y) {
                    rows.push({
                        date: d.x,
                        country: p.id,
                        price: roundNumber(d.y),
                        cellsStyles: {
                            date: {
                                alignment: {vertical: 'middle', horizontal: 'center'}
                            },
                            price: numericStyle,
                        }
                    });
                }
            })
        })
        return {
            'title': defaultSettings.customChartName, filters: expFilters, columns, rows,
            source: defaultSettings.source
        }
    }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
    [SPOT_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
        const {data, filter} = action;
        return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
    },
    [SPOT_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['loadingError'], err);
    },
    [SPOT_LOAD_CHART_DATA_REQUEST]: (state, action) => {
        return state.setIn(['chartData', 'loading'], true);
    },
    [SPOT_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['chartData', 'data'], data).setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
    },
    [SPOT_LOAD_CHART_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
    },
    [SPOT_LOAD_SETTINGS_REQUEST]: (state, action) => {
        return state.setIn(['defaultSettings', 'loading'], true);
    },
    [SPOT_LOAD_SETTINGS_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
    },
    [SPOT_LOAD_SETTINGS_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
    },
    [SPOT_CHANGE_FILTER_VALUE]: (state, action) => {
        const {filter, value} = action;
        return state.setIn(['filtersSelections', filter], value);
    },
    [SPOT_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
        return state.setIn(['translationData', 'loading'], true);
    },
    [SPOT_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['translationData', 'data'], data)
            .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
    },
    [SPOT_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
    },
    [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
        return state.setIn(['noDataText', 'loading'], true);
    },
    [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['noDataText', 'data'], data)
          .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
    },
    [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
    },

};


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
    filtersData: {
        defaults: {
            loaded: false, data: []
        },
        fobProducts: {
            loaded: false, data: []
        },
        dates: {
            loaded: false, data: []
        },
        regions: {
            loaded: false, data: []
        },
        countries: {
            loaded: false,
            data: []
        },
    },
    filtersSelections: {
        countriesSelected: [],
        regionsSelected: [],
        productOriginsSelected:[],
        dates: [],
        unit: '',
        currencyCode: ''
    },
    defaultSettings: {
        loaded: false,
        loading: false,
        data: []
    },
    noDataText: {
        loaded: false,
        loading: false,
        data: {}
    },
    translationData: {
        loaded: false,
        loading: false,
        data: {
            defaultLanguage: 'en',
            translations: {}
        }
    },
    chartData: {
        loaded: false,
        loading: false,
        data: [], convertedData: {}
    }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
}
