import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {OTHERS} from '../../utils/constants';
import {roundNumber, numericStyle} from "../../utils/numberUtils";
import {getText} from '../../utils/translationsUtil';
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
    filters: {
      products: '/api/consumption/products',
      years: '/api/consumption/years',
      defaults: '/api/filtersDefaults/consumption/ApparentConsumption'
    },
    chartData: '/api/consumption/ApparentConsumption',
    settings: '/api/configuration/apparentConsumptionChart',
    noDataText: '/api/settings/noData',
    translations: '/api/dashboardTranslation/all'
  }

// ------------------------------------ Constants ----------------------------------
export const APC_FILTER_LIST_REQUEST = 'APC_LOAD_FILTER_LIST_REQUEST';
export const APC_FILTER_LIST_SUCCESS = 'APC_LOAD_FILTER_LIST_SUCCESS';
export const APC_FILTER_LIST_FAILURE = 'APC_LOAD_FILTER_LIST_FAILURE';

export const APC_CHART_DATA_REQUEST = 'APC_LOAD_CHART_DATA_REQUEST';
export const APC_CHART_DATA_SUCCESS = 'APC_LOAD_CHART_DATA_SUCCESS';
export const APC_CHART_DATA_FAILURE = 'APC_LOAD_CHART_DATA_FAILURE';

export const APC_SETTINGS_REQUEST = 'APC_LOAD_SETTINGS_REQUEST';
export const APC_SETTINGS_SUCCESS = 'APC_LOAD_SETTINGS_SUCCESS';
export const APC_SETTINGS_FAILURE = 'APC_LOAD_SETTINGS_FAILURE';

export const AFC_CHANGE_FILTER_VALUE = 'APC_CHANGE_FILTER_VALUE';

export const APC_COUNTRY_CHANGED = 'APC_COUNTRY_CHANGED';
export const TRANSLATION_DATA_REQUEST = 'TRANSLATION_DATA_REQUEST';
export const TRANSLATION_DATA_SUCCESS = 'TRANSLATION_DATA_SUCCESS';
export const TRANSLATION_DATA_FAILURE = 'TRANSLATION_DATA_FAILURE';

export const AFC_CATEGORIES = [{id: 'CIF', name: 'CIF'}, {id: 'Clearing', name: 'Clearing'}, {
  id: 'Bagging',
  name: 'Bagging'
}, {id: 'Warehousing', name: 'Warehousing'}, {id: 'Finance', name: 'Finance'}, {
  id: 'Road Freight',
  name: 'Road Freight'
}]
const APC_RESET_LOADED_DATA = 'APC_RESET_LOADED_DATA';

const LOAD_NO_DATA_TEXT_REQUEST = 'APC_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'APC_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'APC_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesApparent', 'filtersSelections', 'countryIso']);
    dispatch({'type': APC_FILTER_LIST_REQUEST, filter});
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch({'type': APC_FILTER_LIST_SUCCESS, data, filter});
    }).catch(function (err) {
      dispatch({'type': APC_FILTER_LIST_FAILURE, err, filter});
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['countriesApparent', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({'type': APC_CHART_DATA_REQUEST});
    const params = getState().getIn(['countriesApparent', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({'type': APC_CHART_DATA_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': APC_CHART_DATA_FAILURE, err});
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({'type': APC_SETTINGS_REQUEST});
    const countryIso = getState().getIn(['countriesApparent', 'filtersSelections', 'countryIso']);
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({'type': APC_SETTINGS_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': APC_SETTINGS_FAILURE, err});
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    const data = settings || getState().getIn(['countriesApparent', 'defaultSettings', 'data']);

    const productList = data.topProducts;
    if (productList.find(p => p.name === OTHERS) === undefined) {
      productList.push({id: 0, name: OTHERS});
    }
    dispatch({'type': APC_FILTER_LIST_SUCCESS, data: productList, filter: 'products'});
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'countriesApparent', 'productsSelected', 'years', 'currencyCode');
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesByNutrients', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(`${endpointMapping.translations}?countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const resetFilters = (reload) => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('productsSelected', [], false));
    dispatch(changeFilterValue('years', [], false));
    if (reload) {
      dispatch(setDefaultFilters());
      dispatch(loadChartData());
    }
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({'type': AFC_CHANGE_FILTER_VALUE, filter, value});
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const onCountryChange = (country) => {
  return (dispatch, getState) => {
    dispatch({ 'type': APC_COUNTRY_CHANGED, country });
  }
}

const convertData = (data, state) => {
  const dataFilled = [];
  const yearsSelected = state.getIn(['filtersSelections', 'years']);
  if (yearsSelected && yearsSelected.length) {
    yearsSelected.slice().reverse().forEach(y => { //fill empty years 
      dataFilled.push(data.find(d => d.year === y) || {year: y})
    });
  }
  return dataFilled;
}

export const getFiltersForPrint = () => {
  return (dispatch, getState) => {
    const selectedLanguage = getState().getIn(['intl', 'locale']);
    const translationData = getState().getIn(['countriesApparent', 'translationData']).toJS().data;
    const afcData = getState().getIn(['countriesApparent', 'chartData']);
    let hasUnvalidatedData;
    hasUnvalidatedData = afcData.get('data').some(s => !s.ftwgValidated);
    return hasUnvalidatedData ? [{
      name: getText('general:note', {selectedLanguage, translationData}),
      options: [`* ${getText('use:notValidated', {selectedLanguage, translationData})}`],
      isSettings: true
    }] : [];
  }
}

const getAdditionalData = (period, additionalData, ftwgValidated) => {
  const rows = [];
  let total = 0;
  additionalData.data.forEach(additionalDataItem => {
    if (additionalDataItem.additionalData && additionalDataItem.additionalData.data.length > 0) {
      const returned = getAdditionalData(period, additionalDataItem.additionalData, ftwgValidated);
      rows.push(...returned.rows);
      total = total + returned.total;
    } else {
      total = total + (additionalDataItem.value * 1);
      rows.push({
        year: ftwgValidated ? period : period + '*',
        product: additionalDataItem.name,
        hsCode: additionalDataItem.code && additionalDataItem.code !== 'null' ? additionalDataItem.code : '',
        apparentConsumption: roundNumber(additionalDataItem.value),
        cellsStyles: {
          year: {
            alignment: {vertical: 'middle', horizontal: 'right'}
          },
          apparentConsumption: numericStyle,
        }
      });
    }
  });
  return {rows, total};
}


export const createExportData = () => {
  return (dispatch, getState) => {
    const afcData = getState().getIn(['countriesApparent', 'chartData']);
    const products = getState().getIn(['countriesApparent', 'filtersData', 'products']);
    const afcFilters = getState().getIn(['countriesApparent', 'filtersSelections']);
    const settings = getState().getIn(['countriesApparent', 'defaultSettings', 'data']);
    const selectedLanguage = getState().getIn(['intl', 'locale']);
    const translationData = getState().getIn(['countriesApparent', 'translationData']).toJS().data
    let hasUnvalidatedData = false;
    const productsList = products.get('data').filter(i => afcFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const filters = [
      {name: getText('filter:yearS', {selectedLanguage, translationData}), values: afcFilters.get('years').join(', ')},
      {name: getText('general:products', {selectedLanguage, translationData}), values: productsList.join(', ')}
    ];
    const columns = [
      {headerTitle: getText('filter:year', {selectedLanguage, translationData}), key: 'year', width: 10},
      {headerTitle: getText('general:productHSCode', {selectedLanguage, translationData}), key: 'hsCode', width: 20},
      {headerTitle: getText('general:product', {selectedLanguage, translationData}), key: 'product', width: 30},
      {
        headerTitle: getText('use:apparentConsumptionInMt', {selectedLanguage, translationData}),
        key: 'apparentConsumption',
        width: 40
      }
    ];

    const rows = [];
    afcData.get('data').forEach(yearData => {
      if (yearData.products) {
        let yearTotal = 0;
        hasUnvalidatedData = hasUnvalidatedData ? hasUnvalidatedData : !yearData.ftwgValidated
        yearData.products.forEach(productData => {
          if (productData.additionalData && productData.additionalData.data.length > 0) {
            const returned = getAdditionalData(yearData.year, productData.additionalData, yearData.ftwgValidated);
            rows.push(...returned.rows);
            yearTotal = yearTotal + returned.total;
          } else {
            yearTotal = yearTotal + (productData.value * 1);
            rows.push({
              year: yearData.ftwgValidated ? yearData.year : yearData.year + '*',
              product: productData.name,
              hsCode: productData.code && productData.code !== 'null' ? productData.code : '',
              apparentConsumption: roundNumber(productData.value),
              cellsStyles: {
                year: {
                  alignment: {vertical: 'middle', horizontal: 'right'}
                },
                apparentConsumption: numericStyle,
              }
            });
          }
        });
        rows.push({
          product: `Total ${yearData.year}`,
          apparentConsumption: roundNumber(yearTotal),
          cellsStyles: {
            product: {
              font: {
                name: 'Calibri',
                family: 4,
                bold: true
              },
            },
            apparentConsumption: {
              font: {
                name: 'Calibri',
                family: 4,
                bold: true
              },
              numericStyle,
            },
          }
        });
      }
    });

    return {
      title: settings.customChartName, filters, columns, rows,
      source: settings.source,
      analysisBy: settings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', {
        selectedLanguage,
        translationData
      })}` : null
    }
  }
}

export const resetLoadedData = () => {
  return (dispatch) => {
    dispatch({'type': APC_RESET_LOADED_DATA});
  }
}
// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [APC_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [APC_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [APC_CHART_DATA_REQUEST]: (state, action) => {
    return state.setIn(['chartData', 'loading'], true);
  },
  [APC_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true)
      .setIn(['chartData', 'data'], convertData(data, state));
  },
  [APC_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [APC_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [APC_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'loading'], false).setIn(['defaultSettings', 'data'], data);
  },
  [APC_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [AFC_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [APC_COUNTRY_CHANGED]: (state, action) => {
    const {country} = action;
    return state.setIn(['filtersSelections', 'countryIso'], country);
  },
  [APC_RESET_LOADED_DATA]: (state, action) => {
    return state.setIn(['defaultSettings', 'loaded'], false).setIn(['chartData', 'loaded'], false);
  },
  [TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  view: null,
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
    products: {
      loaded: false, data: []
    }
  },
  filtersSelections: {
    productsSelected: [],
    years: [],
    unit: '',
    currencyCode: '',
    countryIso: '',
  },
  defaultSettings: {
    loaded: false,
    loading: false,
    data: []
  },
  chartData: {
    loaded: false,
    loading: false,
    data: [], convertedData: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  },
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
