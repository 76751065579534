import {combineReducers} from 'redux-immutable';
import {connectRouter} from 'connected-react-router/immutable'
import {intlReducer} from 'react-intl-redux'

import {wordpress} from "@devgateway/wp-react-lib"

import {reducers} from '../embeddable/'
import plantsDirectory from '../dashboard-charts/plantDirectory/module';
import nationalRetailPrices from "../dashboard-charts/nationalRetailPrices/module";
import homeMap from '../dashboard-charts/homemap/module';
import stats from '../dashboard-charts/stats/module';
import imports from '../dashboard-charts/importsChart/module';
import consumption from "../dashboard-charts/consumptionChart/module";
import consumptionOverTime from "../dashboard-charts/consumptionOverTimeChart/module";
import fobPrices from "../dashboard-charts/fobPrices/module";
import totalImportVolumesByCountry from "../dashboard-charts/totalImportVolumesByCountryChart/module";
import totalExportVolumesByCountry from "../dashboard-charts/totalExportVolumesByCountryChart/module";
import historicalFobPrices from "../dashboard-charts/historicalFobPrices/module";
import singleProductPriceOverTime from "../dashboard-charts/singleProductPriceOverTime/module";
import resources from "../dashboard-charts/resources/module";
import countriesByNutrients from "../dashboard-charts/countriesConsumptionChart/consumptionByNutrientTonModule"
import countriesApparent from "../dashboard-charts/countriesConsumptionChart/apparentConsumptionModule"
import countriesRetailPrices from "../dashboard-charts/countriesRetailPrices/retailPricesModule";
import otherCountries from '../dashboard-charts/othersCountries/othersCountriesModule';
import trade from "../dashboard-charts/countriesTradeChart/module";
import exports from "../dashboard-charts/exportsChart/module";
import topFertilizerCountries from "../dashboard-charts/topFertilizersCountries/module";
import production from "../dashboard-charts/productionChart/module";
import totalProductionVolumesByCountry from "../dashboard-charts/totalProductionVolumesByCountryChart/module";
import convertor from "../dashboard-charts/convertor/module";
import retailPriceOverTime from "../dashboard-charts/retailpricesovertime/module";
import retailPriceMap from "../dashboard-charts/retailPriceMap/retailPriceMapModule";
import resourceView from "../dashboard-charts/resourceView/resourceViewModule";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    wordpress,
    ...reducers,
    countriesByNutrients,
    countriesApparent,
    otherCountries,
    countriesRetailPrices,
    plantsDirectory,
    nationalRetailPrices,
    homeMap,
    stats,
    imports,
    totalImportVolumesByCountry,
    totalExportVolumesByCountry,
    fobPrices,
    historicalFobPrices,
    consumption,
    consumptionOverTime,
    singleProductPriceOverTime,
    resources,
    trade,
    exports,
    topFertilizerCountries,
    production,
    totalProductionVolumesByCountry,
    convertor,
    retailPriceOverTime,
    retailPriceMap,
    resourceView,
    intl: intlReducer
})


export default createRootReducer
