import geoStats from 'geostats';
  /**
   * 
   * @param {*} data 
   * @param {*} prevRanges 
   * @returns JSON array of ranges bases on the values uses Jenks
   */
  export  const getBreaks = (data, prevRanges, ranges = 4) => {
      let values = [];
      let rangesParsed = [];
      let parsedData = data.map(d=> {
          return parseFloat(d.total);
      });

      parsedData.forEach(item => {
          if (item > 0) {
              let floor = item * 0.99;
              let ceil = item * 1.01;
              if (values.indexOf(floor) === - 1) {
                  values.push(floor);
              }
              if (values.indexOf(ceil) === - 1) {
                  values.push(ceil);
              }
          }
      });

      if (values.length > 0) {
          const serie = new geoStats(values);
          serie.setColors(['#629328', '#8fc44f', '#bfec8a', '#ebec8a'])
          serie.setPrecision(2);
          let numberOfRanges = values.length > 1 ? values.length -1: values.length;
          serie.getJenks(Math.min(ranges, numberOfRanges));
          serie.ranges.forEach((range) => {
              rangesParsed.push(parseFloat(range.substr(0, range.indexOf('-') - 1)));
              rangesParsed.push(parseFloat(range.substr(range.indexOf('-') + 2, range.length)));
          });
          if (rangesParsed.length > 2 && rangesParsed[0] === rangesParsed[1] && rangesParsed[0] === rangesParsed[2]) {
              rangesParsed = rangesParsed.slice(2);
          }
          return rangesParsed;
      } else {
          return prevRanges;
      }
  }

/**
 *
 * @param {*} arr Array of Objects to be grouped
 * @param {*} k  field to group by
 * @param {*} fn
 */

export const groupBy = (arr, k, fn = () => true) =>
  arr.reduce((r, c) => (fn(c[k]) ? r[c[k]] = [...r[c[k]] || [], c] : null, r), {});

export const invertLatLongFromCoordinates = (coordinates) => {
    coordinates.forEach(c => {
        if (Array.isArray(c[0])) {
            invertLatLongFromCoordinates(c);
        } else if (!Number.isNaN(c[0]) && !Number.isNaN(c[1])) {
            c.reverse();
        }
    })
}