import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
    filters: {
      years: '/api/consumption/regional/yearsList',
      countries: '/api/consumption/regional/countriesList',
      products:'/api/consumption/products',
      defaults: '/api/filtersDefaults/consumption/ApparentConsumption'
    },
    chartData: '/api/consumption/ApparentConsumption',
    settings: '/api/configuration/apparentConsumptionByCountryChart',
    noDataText: '/api/settings/noData',
    translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const CC_LOAD_FILTER_LIST_REQUEST = 'CC_LOAD_FILTER_LIST_REQUEST';
const CC_LOAD_FILTER_LIST_SUCCESS = 'CC_LOAD_FILTER_LIST_SUCCESS';
const CC_LOAD_FILTER_LIST_FAILURE = 'CC_LOAD_FILTER_LIST_FAILURE';

const CC_LOAD_CHART_DATA_REQUEST = 'CC_LOAD_CHART_DATA_REQUEST';
const CC_LOAD_CHART_DATA_SUCCESS = 'CC_LOAD_CHART_DATA_SUCCESS';
const CC_LOAD_CHART_DATA_FAILURE = 'CC_LOAD_CHART_DATA_FAILURE';

const CC_LOAD_SETTINGS_REQUEST = 'CC_LOAD_SETTINGS_REQUEST';
const CC_LOAD_SETTINGS_SUCCESS = 'CC_LOAD_SETTINGS_SUCCESS';
const CC_LOAD_SETTINGS_FAILURE = 'CC_LOAD_SETTINGS_FAILURE';

const CC_LOAD_TRANSLATION_DATA_REQUEST = 'CC_LOAD_TRANSLATION_DATA_REQUEST';
const CC_LOAD_TRANSLATION_DATA_SUCCESS = 'CC_LOAD_TRANSLATION_DATA_SUCCESS';
const CC_LOAD_TRANSLATION_DATA_FAILURE = 'CC_LOAD_TRANSLATION_DATA_FAILURE';

const CC_CHANGE_FILTER_VALUE = 'CC_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'CC_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'CC_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'CC_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CC_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': CC_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': CC_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['consumption', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CC_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch({ 'type': CC_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': CC_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': CC_LOAD_CHART_DATA_REQUEST });
    const params = getState().getIn(['consumption', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({ 'type': CC_LOAD_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': CC_LOAD_CHART_DATA_FAILURE, err });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': CC_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': CC_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': CC_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'consumption', 'productsSelected', 'countriesSelected', 'yearSelected');
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(setDefaultFilters(getState().getIn(['consumption', 'defaultSettings', 'data'])));
    dispatch(loadChartData());   
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CC_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const consumptionFilters = getState().getIn(['consumption', 'filtersSelections']);
    const consumptionData = getState().getIn(['consumption', 'chartData', 'data']);
    const years = getState().getIn(['consumption', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => consumptionFilters.get('yearSelected') === i);
    const countries = getState().getIn(['consumption', 'filtersData', 'countries', 'data']);
    const countriesList = countries.filter(i => consumptionFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);

    const products = getState().getIn(['consumption', 'filtersData', 'products', 'data']);
    const productsList = products.filter(i => consumptionFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const retValue = [
      {name: getText('general:products', props), options: productsList},
      {name: getText('filter:year', props), options: yearList},
      {name: getText('general:countries', props), options: countriesList},
    ];
    let hasUnvalidatedData = consumptionData.filter(d => d.ftwgValidated === false).length > 0;
    if (hasUnvalidatedData) {
      retValue.unshift({name: getText('general:note', props), options:[`* ${getText('use:notValidated', props)}`], isSettings: true})
    }
    return retValue;
  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const consumptionFilters = getState().getIn(['consumption', 'filtersSelections']);
    const consumptionData = getState().getIn(['consumption', 'chartData', 'data']);
    const defaultSettings = getState().getIn(['consumption', 'defaultSettings', 'data']);
    const years = getState().getIn(['consumption', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => consumptionFilters.get('yearSelected') === i);
    const countries = getState().getIn(['consumption', 'filtersData', 'countries', 'data']);
    const countriesList = countries.filter(i => consumptionFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const filters = [
      {name: getText('filter:year', props), values: yearList.join(', ')},
      {name: getText('general:countries', props), values: countriesList.join(', ')},
    ];

    const columns = [
      {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 40},
      {headerTitle: getText('general:product', props), key: 'product', width: 40},
      {headerTitle: getText('use:apparentConsumptionInMt', props), key: 'consumption', width: 35}
    ];

    const rows = [];
    let hasUnvalidatedData = false;
    consumptionData.forEach(d => {
      const {country, ftwgValidated, products} = d;
      if (!ftwgValidated) {
        hasUnvalidatedData = true;
      }
      if (products) {
        products.forEach(p => {
          if (p.additionalData && p.additionalData.data && p.additionalData.data.length > 0) {
            const returned = getAdditionalData(country.name, p.additionalData, d.ftwgValidated);
            rows.push(...returned.rows);
          } else {
            rows.push({
              country: ftwgValidated ? country.name : `${country.name}*`,
              product: p.name,
              consumption: p.value,
              cellsStyles: {
                consumption: {
                  alignment: {vertical: 'middle', horizontal: 'right'},
                  numFmt: '#,##0.00'
                }
              }
            });
          }
        })
      }
    })

    return {
      'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', props)}` : null
    }
  }
}

const getAdditionalData = (country, additionalData, ftwgValidated) => {
  const rows = [];
  let total = 0;
  additionalData.data.forEach(additionalDataItem => {
    if (additionalDataItem.additionalData && additionalDataItem.additionalData.data.length > 0) {
      const returned = getAdditionalData(country, additionalDataItem.additionalData, ftwgValidated);
      rows.push(...returned.rows);
      total = total + returned.total;
    } else {
      total = total + (additionalDataItem.value * 1);
      rows.push({
        country: ftwgValidated ? country : `${country}*`,
        product: additionalDataItem.name,
        consumption: additionalDataItem.value,
        cellsStyles: {
          consumption: {
            alignment: { vertical: 'middle', horizontal: 'right' },
            numFmt: '#,##0.00'
          }
        }
      });
    }
  });
  return {rows, total};
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [CC_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [CC_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [CC_LOAD_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [CC_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'data'], data).setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [CC_LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [CC_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [CC_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [CC_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [CC_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [CC_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [CC_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [CC_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
    countries: {
      loaded: false,
      data: []
    },
    products: {
      loaded: false,
      data: []
    },
  },
  filtersSelections: {
    yearSelected: null,
    countriesSelected: [],
    productsSelected: [],
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: {}
  },
  chartData: {
    loaded: false, 
    loading: false, 
    data: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
