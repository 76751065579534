import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {formatMonthYear, getMonthsShort} from '../../utils/dateUtils';
import {getChartUnitsFromSettingsData, updateWithCompoundIds} from '../../utils/dataUtils';
import {DATES_TYPES, MONTHS_SHORT} from "../../utils/constants";
import {roundNumber, numericStyle} from "../../utils/numberUtils";
import {getText} from '../../utils/translationsUtil';
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
  filters: {
    products: '/api/prices/compoundProductsList',
    defaults: '/api/filtersDefaults/prices/seriesByProducts',
    dates: '/api/prices/datesList',
    locations: '/api/prices/locations',
  },
  chartData: '/api/prices/seriesByProducts',
  settings: '/api/configuration/retailPriceEvolutionChart',
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}

// ------------------------------------ Constants ----------------------------------
export const PRICES_LOAD_FILTER_LIST_REQUEST = 'PRICES_LOAD_FILTER_LIST_REQUEST';
export const PRICES_LOAD_FILTER_LIST_SUCCESS = 'PRICES_LOAD_FILTER_LIST_SUCCESS';
export const PRICES_LOAD_FILTER_LIST_FAILURE = 'PRICES_LOAD_FILTER_LIST_FAILURE';

export const PRICES_LOAD_CHART_DATA_REQUEST = 'PRICES_LOAD_CHART_DATA_REQUEST';
export const PRICES_LOAD_CHART_DATA_SUCCESS = 'PRICES_LOAD_CHART_DATA_SUCCESS';
export const PRICES_LOAD_CHART_DATA_FAILURE = 'PRICES_LOAD_CHART_DATA_FAILURE';

export const PRICES_LOAD_SETTINGS_REQUEST = 'PRICES_LOAD_SETTINGS_REQUEST';
export const PRICES_LOAD_SETTINGS_SUCCESS = 'PRICES_LOAD_SETTINGS_SUCCESS';
export const PRICES_LOAD_SETTINGS_FAILURE = 'PRICES_LOAD_SETTINGS_FAILURE';

export const PRICES_CHANGE_FILTER_VALUE = 'PRICES_CHANGE_FILTER_VALUE';
export const PRICES_CHANGE_TIME_PERIOD = 'PRICES_CHANGE_TIME_PERIOD';

const PRICES_TRANSLATION_DATA_REQUEST = 'PRICES_TRANSLATION_DATA_REQUEST';
const PRICES_TRANSLATION_DATA_SUCCESS = 'PRICES_TRANSLATION_DATA_SUCCESS';
const PRICES_TRANSLATION_DATA_FAILURE = 'PRICES_TRANSLATION_DATA_FAILURE';

const PRICES_COUNTRY_CHANGED = 'PRICES_COUNTRY_CHANGED';
const PRICES_RESET_LOADED_DATA = 'PRICES_RESET_LOADED_DATA';

const LOAD_NO_DATA_TEXT_REQUEST = 'PRICES_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'PRICES_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'PRICES_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}


export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesRetailPrices', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': PRICES_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(`${endpointMapping.translations}?countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': PRICES_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': PRICES_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['countriesRetailPrices', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_LOAD_FILTER_LIST_REQUEST, filter });
    const countryIso = getState().getIn(['countriesRetailPrices', 'filtersSelections', 'countryIso']);
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': PRICES_LOAD_FILTER_LIST_SUCCESS, data, filter });
      if (filter === 'dates') {
        const years = [];
        data.forEach(d => {
          const year = parseInt(d.split('-')[0]);
          if (years.indexOf(year) === -1) {
            years.push(year);
          }
        })
        const yearsFromRange = [];
        for (let i = years[0]; i <= years[years.length - 1]; i++) {
          yearsFromRange.push(i);
        }
        dispatch({ 'type': PRICES_LOAD_FILTER_LIST_SUCCESS, data: yearsFromRange, filter: 'years'});
      }
    }).catch(function(err) {
      dispatch({ 'type': PRICES_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_LOAD_CHART_DATA_REQUEST });
    const params = getState().getIn(['countriesRetailPrices', 'filtersSelections']).toJS();
    const countryIso = getState().getIn(['countriesRetailPrices', 'filtersSelections', 'countryIso']);
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(`${endpointMapping.chartData}?countryIso=${countryIso}`, params).then(data => {
      dispatch({ 'type': PRICES_LOAD_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PRICES_LOAD_CHART_DATA_FAILURE, err });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_LOAD_SETTINGS_REQUEST });
    const countryIso = getState().getIn(['countriesRetailPrices', 'filtersSelections', 'countryIso']);
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      let unitList = getChartUnitsFromSettingsData(data);
      dispatch({ 'type': PRICES_LOAD_FILTER_LIST_SUCCESS, data: unitList, filter: 'units'});
      dispatch(setDefaultFilters(data));
      const defaultPeriod = data.defaultDateType.code;
      dispatch(changeTimePeriod(defaultPeriod));
      dispatch({ 'type': PRICES_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PRICES_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const onCountryChange = (country) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_COUNTRY_CHANGED, country });
  }
}

const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'countriesRetailPrices', 'unit', 'townSelected',
        'yearSelected', 'years', 'dates', 'compoundProductsSelected', 'currencyCode');
  }
}

export const resetFilters = (reload) => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('dates', [], false));     
    dispatch(changeFilterValue('townSelected', 0, false));
    dispatch(changeFilterValue('compoundProductsSelected', [], false));
    if (reload) {
      dispatch(setDefaultFilters());
      dispatch(loadChartData());
    }
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_CHANGE_FILTER_VALUE, filter, value });
    if (filter === 'unit') { //if unit changes, it force to change the currency value
      dispatch({ 'type': PRICES_CHANGE_FILTER_VALUE, filter: 'currencyCode', value: value.split('_')[0] });
    }
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const getFiltersForPrint = () => {
  return (dispatch, getState) => {
    const locations = getState().getIn(['countriesRetailPrices', 'filtersData', 'locations']);
    const potFilters= getState().getIn(['countriesRetailPrices', 'filtersSelections']);
    const selectedLanguage = getState().getIn(['countriesRetailPrices', 'selectedLanguage']);
    const translationData = getState().getIn(['countriesRetailPrices', 'translationData']).toJS().data;
    const datesList = [];
    const startDate = potFilters.get('dates')[0] ? new Date(potFilters.get('dates')[0].replace(/-/g, '/')) : null; //replace hyphens to avoid issue with day offset due timezone
    const endDate = potFilters.get('dates')[1] ? new Date(potFilters.get('dates')[1].replace(/-/g, '/')) : null;
    datesList.push(`${getText('filter:from', {selectedLanguage, translationData})} ${formatMonthYear(startDate)}`);
    datesList.push(`${getText('filter:to', {selectedLanguage, translationData})} ${formatMonthYear(endDate)}`);
    const locationList = locations.get('data').filter(i => potFilters.get('townSelected') === i.id).map(i => i.vifaaName);
    return [
      {name: getText('general:locations', {selectedLanguage, translationData}), options: locationList.length ? locationList : ['National']}
    ];
  }
}

export const createExportData = () => {
  return (dispatch, getState) => {
    const timePeriod = getState().getIn(['countriesRetailPrices', 'timePeriod']);
    const potData = getState().getIn(['countriesRetailPrices', 'chartData']);
    const products = getState().getIn(['countriesRetailPrices', 'filtersData', 'products']);
    const locations = getState().getIn(['countriesRetailPrices', 'filtersData', 'locations']);
    const potFilters= getState().getIn(['countriesRetailPrices', 'filtersSelections']);
    const lang = getState().getIn(['intl', 'locale']);
    const selectedLanguage = getState().getIn(['countriesRetailPrices', 'selectedLanguage']);
    const translationData = getState().getIn(['countriesRetailPrices', 'translationData']).toJS().data;
    const datesList = [];
    const startDate = potFilters.get('dates')[0] ? new Date(potFilters.get('dates')[0].replace(/-/g, '/')) : null; //replace hyphens to avoid issue with day offset due timezone
    const endDate = potFilters.get('dates')[1] ? new Date(potFilters.get('dates')[1].replace(/-/g, '/')) : null;
    const startYear = potFilters.get('years')[0];
    const endYear = potFilters.get('years')[potFilters.get('years').length -1];
    endDate.setDate(endDate.getDate() - 2);
    if (timePeriod === DATES_TYPES.MONTHS) {
      datesList.push(`${getText('filter:from', {selectedLanguage, translationData})} ${formatMonthYear(startDate)}`);
      datesList.push(`${getText('filter:to', {selectedLanguage, translationData})} ${formatMonthYear(endDate)}`);
    } else {
      datesList.push(`${getText('filter:from', {selectedLanguage, translationData})} ${startYear}`);
      datesList.push(`${getText('filter:to', {selectedLanguage, translationData})} ${endYear}`);
    }
    const productsList = products.get('data').filter(i => potFilters.get('compoundProductsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const locationList = locations.get('data').filter(i => potFilters.get('townSelected') === i.id).map(i => i.vifaaName);
    const expFilters = [
      {name: getText('general:dateRange', {selectedLanguage, translationData}), values: datesList.join(', ')},
      {name: getText('general:products', {selectedLanguage, translationData}), values: productsList.length === products.length ? '' : productsList.join(', ')},
      {name: getText('general:locations', {selectedLanguage, translationData}), values: locationList.join(', ')}
    ];
    const columns = [
      {headerTitle: getText('availability:date', {selectedLanguage, translationData}), key: 'date', width: 25},
      {headerTitle: getText('general:productHSCode', {selectedLanguage, translationData}), key: 'hsCode', width: 25},
      {headerTitle: getText('use:fertilizer', {selectedLanguage, translationData}), key: 'fertilizer', width: 55},
      {headerTitle: `${getText('general:openMarketRetail', {selectedLanguage, translationData})} - ${potFilters.get('unit')}`, key: 'price', width: 30}
    ];
    const rows = [];
    const data = timePeriod === DATES_TYPES.MONTHS ? potData.get('monthlyData') : potData.get('yearlyData');
    const periods = [];
    data.forEach(i => { //get all periods
      i.data.forEach(p => {
        if (periods.indexOf(p.x) === -1) {
          periods.push(p.x)
        }
      })
    });
    const periodsSorted = periods.sort((p1, p2) => {
      const p1month = getMonthsShort(lang).indexOf(p1.split('-')[0]);
      const p1year = parseInt(p1.split('-')[1]);
      const p2month = getMonthsShort(lang).indexOf(p2.split('-')[0]);
      const p2year = parseInt(p2.split('-')[1]);
      if (p2year < p1year) {
        return 1;
      } else if (p2year > p1year) {
        return -1;
      } else {
        if (p2month < p1month) {
          return 1;
        } else if (p2month > p1month) {
          return -1
        }
      }
      return 0;
    });
    periodsSorted.forEach(period => {
      data.forEach(p => {
        const periodData = p.data.find(d => d.x === period);
        if (periodData && periodData.y) {
          rows.push({
            date: periodData.x,
            hsCode: p.seriesInfo.hsCode && p.seriesInfo.hsCode !== 'null' ? p.seriesInfo.hsCode : '',
            fertilizer: p.id,
            price: roundNumber(periodData.y),
            cellsStyles: {
              date: {
                alignment: { vertical: 'middle', horizontal: 'center' }
              },
              price: numericStyle,
              hsCode: {
                alignment: { vertical: 'middle', horizontal: 'left' }
              }
            }
          });
        }
      })
    });
    return {'title': 'Evolution of Retail Price Over Time', filters: expFilters, columns, rows, source: 'AfricaFertilizer.org'}
  }
}

export const changeTimePeriod = (timePeriod) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PRICES_CHANGE_TIME_PERIOD, timePeriod });
  }
}

export const resetLoadedData = () => {
  return (dispatch) => {
    dispatch({'type': PRICES_RESET_LOADED_DATA});
  }
}
// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [PRICES_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [PRICES_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [PRICES_LOAD_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [PRICES_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'monthlyData'], data.serieByMonth || [])
      .setIn(['chartData', 'yearlyData'], data.serieByYear || [])
      .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [PRICES_LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICES_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [PRICES_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'loading'], false).setIn(['defaultSettings', 'data'], data);
  },
  [PRICES_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICES_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [PRICES_CHANGE_TIME_PERIOD]: (state, action) => {
    const {timePeriod} = action;
    return state.setIn(['timePeriod'], timePeriod);
  },
  [PRICES_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [PRICES_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [PRICES_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICES_COUNTRY_CHANGED]: (state, action) => {
    const {country} = action;
    return state.setIn(['filtersSelections', 'countryIso'], country);
  },
  [PRICES_RESET_LOADED_DATA]: (state, action) => {
    return state.setIn(['defaultSettings', 'loaded'], false).setIn(['chartData', 'loaded'], false);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  view: 'bar',
  timePeriod: DATES_TYPES.MONTHS,
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
    dates: {
      loaded: false
      , data: []
    },
    products: {
      loaded: false, data: []
    },
    locations: {
      loaded: false, data: []
    },
    units: {
      loaded: false, data: []
    }
  },
  filtersSelections: {
    compoundProductsSelected: [],
    years: [],
    dates: [],
    townSelected: 0,
    unit: '',
    currencyCode: '',
    countryIso:'',
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: []
  },
  chartData: {
    loaded: false, 
    loading: false,
    monthlyData: [],
    yearlyData: [],
    data: [], convertedData: {}
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
