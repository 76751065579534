import {VIFAA_COLORS} from "./constants";
import tinycolor from "tinycolor2";
import React, {Fragment} from "react";
import {line} from "d3-shape";
import {formatNumber} from './numberUtils';
import {cloneData} from "./dataUtils";
import {getText} from "./translationsUtil";

const ABUJA_TARGET = 50;


export const propHasData = (dataProp) => dataProp.get('loaded') && dataProp.get('data').length > 0;

export const getColor = (item, colorMap, usedColors, reverse) => {
    let id = item.id;
    let previousColor = colorMap[id];
    if (previousColor && (!usedColors[previousColor] || usedColors[previousColor] === id)) {
        usedColors[previousColor] = id;
        return previousColor;
    } else {
        let colors = VIFAA_COLORS.slice();
        if (reverse) {
            colors = colors.reverse();
        }
        const anyUnusedColor = colors.filter(color => {
            return (!usedColors[color]);
        })[0];

        colorMap[id] = anyUnusedColor;
        usedColors[anyUnusedColor] = id;
        return anyUnusedColor;
    }
}

export const getTotalLabel = (item, keys, nivoBars) =>{
    let lastInColumn = '';
    for (let i = keys.length - 1; i >= 0; i--) {
        if (nivoBars.filter(n => n.width > 0 && n.height > 0).find(b => {
            return b.key === `${keys[i]}.${item.indexValue}`
        })) {
            lastInColumn = keys[i];
            break;
        }
    }
    if (item.id === lastInColumn) {
        let total = 0;
        keys.forEach(k => total = item.data[k] ? total + item.data[k] : total);
        return {key: `${item.id}.${item.indexValue}`, value: `${formatNumber(total)}`};
    } else {
        return {key: `${item.id}.${item.indexValue}`, value: ''};
    }
}

export const noDataForSelectedFilter = (props) => {
    return (<div className="no-data"><p>{getText('use:noDataForTheSelectedFilterCombination', props)}</p></div>);
}

//TODO: see if there is a better of detecting mobile devices
export const isMobile = () => {
    const isMobile = window.matchMedia("only screen and (max-width: 760px)");
    return isMobile.matches
}

const OPACITY = 0.3;
export const getGrayedOutColor = (color) => {
    let grayedOutColor = tinycolor(color);
    grayedOutColor.setAlpha(OPACITY);
    return grayedOutColor.toString();
}

const parseItemDate = (date) => {
    if (date && !date.startsWith('Q')) {
        let dateParts = date.split("-");
        if (dateParts.length === 2) {
            return new Date('01-' + date);
        } else if (dateParts.length === 3) {
            return new Date(date);
        }
    }

    return null;
}


export const dispatchFilterCustomDefaults = (changeFilterValue, dispatch, getState, customDefaults, key, ...property) => {
    property.forEach(p => {
        const defaultValue = getState().getIn([key, 'filtersData', customDefaults, 'data', p]);
        if (defaultValue) {
            dispatch(changeFilterValue(p, cloneData(defaultValue), false));
        }
    });
}

export const dispatchFilterDefaults = (changeFilterValue, dispatch, getState, key, ...property) => {
    dispatchFilterCustomDefaults(changeFilterValue, dispatch, getState, 'defaults', key, ...property);
}


const parseSortingDate = (date) => {
    let dateParts = date.split("-");
    return new Date(dateParts[0], dateParts[1], dateParts[2]);
}

export const getTransformedData = (xKeys, data, usedColors, colorMap, sortFunction) => {
    let transformedData = data.map(d => {
        if (d.data) {
            d.data = d.data.map(dd => {
                if (dd.x && !xKeys.includes(dd.x)) {
                    xKeys.push(dd.x);
                }
                return dd;
            });
        }
        return d;
    });
    transformedData = transformedData.map(d => {
        xKeys.forEach(x => {
            if (!d.data) {
                d.data = [];
            }
            let exists = d.data.filter(d => d.x === x)[0];
            if (!exists) {
                let sortingDate;
                if (x.startsWith('Q')) {
                    let dateParts = x.split('-');
                    let month = parseInt(dateParts[0].replace(/\D/g, ''));
                    let year = parseInt(dateParts[1]);
                    sortingDate = new Date(year, month, 1);
                } else {
                    sortingDate = new Date(x);
                }
                d.data.push({x: x, y: null, sortingDate: sortingDate});
            } else if (!exists.y) {
                exists.y = null;
            }
        });

        if (sortFunction) {
            d.data = d.data.sort(sortFunction);
        } else {
            d.data = d.data.sort((a, b) => {
                return new Date(a.sortingDate).getTime() - new Date(b.sortingDate).getTime();
            });
        }
        return d;
    });

    usedColors = {};
    const keys = transformedData.map(item => {
        return item.id
    });
    keys.forEach(k => {
        getColor({id: k}, colorMap, usedColors);
    });
    return {transformedData, xKeys, usedColors};
}

export const getMinMaxValues = (data) => {
    if (!data || !data.length) {
        data = [];
    }
    let flattenedData = data.length > 0 ? data.map(d => d.data).flat().map(item => item && item.y).filter(i => i != null) : [];
    return {min: Math.min.apply(null, flattenedData), max: Math.max.apply(null, flattenedData)};
}

export const createMissingData = (d, currentLine) => {
    let lineColor = "";
    return ({points, xScale, yScale}) => {
        const lineGenerator = line()
            .x(d => xScale(d.x))
            .y(d => yScale(d.y));
        let fragmentArray = [];

        d.forEach(item => {
            const data = item.data;
            const match = points.filter(p => p.id.indexOf(item.id + '.') !== -1);
            if (match.length > 1 && data.length === match.length) {
                return;
            }
            const data1 = item.data.filter((d) => d.y !== null);
            if (data1.length === 1) {
                const point = points.filter(i => i.serieId === item.id)[0];
                fragmentArray.push((<circle
                    key={point.serieId}
                    cx={point.x}
                    cy={point.y}
                    r={2}
                    fill={point.serieColor}
                    stroke={point.borderColor}
                    style={{pointerEvents: "all"}}
                />));
                return fragmentArray;
            }
            data.filter(i => i.y !== null)
            let pointsArray = [];
            data.forEach((d, index) => {
                if (index > 0 && index < data.length - 1 && d.y === null && data[index - 1].y !== null
                    && data[index + 1].y !== null) {
                    const hasPrevious = data[index - 1].y !== null;
                    const hasNext = data[index + 1].y !== null;
                    let avg = 0;
                    if (hasPrevious && hasNext) {
                        const arr = [];
                        arr.push(parseFloat(data[index - 1].y));
                        arr.push(parseFloat(data[index + 1].y));
                        avg = median(arr);
                    } else if (hasPrevious) {
                        avg = parseFloat(data[index - 1].y);
                    } else if (hasNext) {
                        avg = parseFloat(data[index + 1].y);
                    }
                    pointsArray.push({x: d.x, y: avg});
                } else if (d.y !== null) {
                    pointsArray.push({x: d.x, y: d.y});
                }
            }, (points));
            if (match.length > 0 && currentLine) {
                lineColor = (match[0].serieId === currentLine) ? "rgb(134, 138, 135)" : getGrayedOutColor(match[0].serieColor);
            } else {
                lineColor = "rgb(134, 138, 135)";
            }

            fragmentArray.push((
                <Fragment>
                    <path
                        d={lineGenerator(pointsArray)}
                        fill="none"
                        stroke={lineColor}
                        style={{pointerEvents: "none", strokeDasharray: '20,10,5,5,5,10'}}
                    />
                </Fragment>
            ))
        })
        return fragmentArray;

    }
}

//Calculate Median from an array of numbers.
const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const createAbujaTargetLineForLineChart = () => {
    const lineColor = "rgba(0, 0, 0, 1)";
    return ({points, xScale, yScale, height, width}) => {
        let years = [];
        points.forEach(point => {
            let year = parseInt(point.data.x);
            if (years.indexOf(year) === -1) {
                years.push(year);
            }
        });

        /* this does not seem to be used
        let mid = Math.floor(years.length / 2)
        if (Math.floor(years.length % 2) === 0) {
          mid = mid !== 0 ? mid - 1 : mid;
        }
        */

        let hasSinglePoint = false;
        if (points.length === 1) {
            hasSinglePoint = true;
            let start = Object.assign({}, points[0]);
            let end = Object.assign({}, points[0]);
            start.start = true;
            end.start = false;
            points = [start, end];
        }
        const lineGenerator = line()
            .x(point => {
                if (hasSinglePoint) {
                    return point.start === true ? xScale(point.data.x) - (0.9 * width) / 2 : xScale(point.data.x) + (0.9 * width) / 2
                }
                return xScale(point.data.x);
            })
            .y(point => yScale(ABUJA_TARGET));

        const uniqueXPoints = []
        points.forEach(point => {
            if (!uniqueXPoints.find(p => p.data.x === point.data.x)) {
                uniqueXPoints.push(point);
            }
        })
        uniqueXPoints.sort((p1, p2) => {
            return p1.data.x * 1 - p2.data.x * 1;
        })
        return (
            <Fragment>
                <text className="target-line" x={(width / 5) * 1.5} y={yScale(ABUJA_TARGET) + 17} fill="black"> Abuja
                    Declaration Target: 50 kg/ha of product
                </text>
                <path
                    d={lineGenerator(uniqueXPoints)}
                    fill="none"
                    stroke={lineColor}
                    style={{pointerEvents: "none", strokeDasharray: '4 4'}}
                />
            </Fragment>
        );
    };
}

export const createAbujaTargetLineForBarChart = () => {
    const lineColor = "rgba(0, 0, 0, 1)";
    return ({bars, xScale, yScale, width}) => {
        let years = [];
        const barWidth = bars && bars.length > 0 ? bars[0].width / 2 : 0;
        bars.forEach(bar => {
            if (bar.data.data.year && years.indexOf(bar.data.data.year) === -1) {
                years.push(bar.data.data.year);
            }
        });

        let minYear = years[0];
        let maxYear = years[years.length - 1];

        let hasSinglePoint = false;
        if (years.length === 1) {
            hasSinglePoint = true;
            years = [years[0], years[0]];
        }

        const lineGenerator = line()
            .x((year, index) => {
                if (hasSinglePoint) {
                    return index === 0 ? xScale(year) - (((0.9 * width) - barWidth) / 4) : xScale(year) + barWidth * 2;
                }

                if (year === minYear) {
                    return xScale(year) - (0.9 * barWidth) / 2;
                } else if (year === maxYear) {
                    return xScale(year) + barWidth * 2;
                } else {
                    return xScale(year) + barWidth;
                }
            }).y(year => yScale(ABUJA_TARGET));

        return (
            <Fragment>
                <text className="target-line" x={(width / 5) * 1.5} y={yScale(ABUJA_TARGET) + 15} fill="black"> Abuja
                    Declaration Target: 50 kg/ha of nutrients
                </text>
                <path
                    d={lineGenerator(years)}
                    fill="none"
                    stroke={lineColor}
                    style={{pointerEvents: "none", strokeDasharray: '4 4'}}
                />
            </Fragment>
        );
    };
}

export const getTotalLabelForStackedBarchart = (item, keys, nivoBars) => {
    let lastInColumn = '';
    for (let i = keys.length - 1; i >= 0; i--) {
        if (nivoBars.filter(n => n.height > 0).find(b => b.key === `${keys[i]}.${item.indexValue}`)) {
            lastInColumn = keys[i];
            break;
        }
    }

    if (item.id === lastInColumn) {
        let total = 0;
        keys.forEach(k => total = item.data[k] ? total + item.data[k] : total);
        return `${formatNumber(total)}`;
    } else {
        return '';
    }
}

export const hasMissingData = (xKeys, data) => {
    let hasMissingData = false;
    data.forEach(d => {
        let lineDropped = false;
        for (let i = 0; i < xKeys.length - 1; i++) {
            const currentValue = d.data.find(d => d.x === xKeys[i]);
            const nextValue = d.data.find(d => d.x === xKeys[i + 1]);
            if (currentValue.y && !nextValue.y) {
                lineDropped = true;
            } else if (!currentValue.y && nextValue.y && lineDropped) {
                hasMissingData = true;
            }
        }
    })
    return hasMissingData;
}

