import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {OTHERS} from "../../utils/constants";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
  filters: {
    years: '/api/consumption/trade/years',
    defaults: '/api/filtersDefaults/consumption/trade'
  },
  chartData: '/api/consumption/trade',
  settings: '/api/configuration/tradeChart',
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const TD_LOAD_FILTER_LIST_REQUEST = 'TD_LOAD_FILTER_LIST_REQUEST';
const TD_LOAD_FILTER_LIST_SUCCESS = 'TD_LOAD_FILTER_LIST_SUCCESS';
const TD_LOAD_FILTER_LIST_FAILURE = 'TD_LOAD_FILTER_LIST_FAILURE';

const TD_LOAD_CHART_DATA_REQUEST = 'TD_LOAD_CHART_DATA_REQUEST';
const TD_LOAD_CHART_DATA_SUCCESS = 'TD_LOAD_CHART_DATA_SUCCESS';
const TD_LOAD_CHART_DATA_FAILURE = 'TD_LOAD_CHART_DATA_FAILURE';

const TD_LOAD_SETTINGS_REQUEST = 'TD_LOAD_SETTINGS_REQUEST';
const TD_LOAD_SETTINGS_SUCCESS = 'TD_LOAD_SETTINGS_SUCCESS';
const TD_LOAD_SETTINGS_FAILURE = 'TD_LOAD_SETTINGS_FAILURE';

const TD_LOAD_TRANSLATION_DATA_REQUEST = 'TD_LOAD_TRANSLATION_DATA_REQUEST';
const TD_LOAD_TRANSLATION_DATA_SUCCESS = 'TD_LOAD_TRANSLATION_DATA_SUCCESS';
const TD_LOAD_TRANSLATION_DATA_FAILURE = 'TD_LOAD_TRANSLATION_DATA_FAILURE';

const TD_CHANGE_FILTER_VALUE = 'TD_CHANGE_FILTER_VALUE';
const TD_RESET_LOADED_DATA = 'TD_RESET_LOADED_DATA';

const LOAD_NO_DATA_TEXT_REQUEST = 'TD_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'TD_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'TD_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TD_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': TD_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': TD_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['trade', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TD_LOAD_FILTER_LIST_REQUEST, filter });
    const countryIso = getState().getIn(['trade', 'filtersSelections', 'countryIso']);
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': TD_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': TD_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': TD_LOAD_CHART_DATA_REQUEST });
    const params = getState().getIn(['trade', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({ 'type': TD_LOAD_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': TD_LOAD_CHART_DATA_FAILURE, err });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': TD_LOAD_SETTINGS_REQUEST });
    const countryIso = getState().getIn(['trade', 'filtersSelections', 'countryIso']) ;
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      const productList = data.topProducts;
      if (productList.find(p => p.name === OTHERS) === undefined) {
        productList.push({id: 0, name: OTHERS});
      }
      dispatch({'type': TD_LOAD_FILTER_LIST_SUCCESS, data: productList, filter: 'products'});
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': TD_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': TD_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'trade', 'years', 'productsSelected');
  }
}

export const resetFilters = (reload) => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('years', [], false));
    if (reload) {
      dispatch(setDefaultFilters());
      dispatch(loadChartData());
    }
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TD_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const tradeFilters = getState().getIn(['trade', 'filtersSelections']);
    const tradeData = getState().getIn(['trade', 'chartData', 'data']);
    const years = getState().getIn(['trade', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => tradeFilters.get('years').indexOf(i) != -1);
    const products = getState().getIn(['trade', 'filtersData', 'products', 'data']);
    const productsList = products.filter(i => tradeFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const retValue = [
      {name: getText('filter:yearS', props), options: yearList},
      {name: getText('general:products', props), options: productsList},
    ];
    let hasUnvalidatedData = tradeData.filter(d => d.data.filter(dd => dd.ftwgValidated === false).length).length > 0;
    if (hasUnvalidatedData) {
      retValue.unshift({name: getText('general:note', props), options:[`* ${getText('use:notValidated', props)}`], isSettings: true})
    }
    return retValue;
  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const tradeFilters = getState().getIn(['trade', 'filtersSelections']);
    const tradeData = getState().getIn(['trade', 'chartData', 'data']);
    const defaultSettings = getState().getIn(['trade', 'defaultSettings', 'data']);
    const years = getState().getIn(['trade', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => tradeFilters.get('years').indexOf(i) != -1);
    const products = getState().getIn(['trade', 'filtersData', 'products', 'data']);
    const productsList = products.filter(i => tradeFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const filters = [
      {name: getText('filter:yearS', props), values: yearList.join(', ')},
      {name: getText('general:products', props), values: productsList.join(', ')},
    ];

    const columns = [
      {headerTitle: getText('filter:year', props), key: 'year', width: 40},
      {headerTitle: getText('general:trade', props), key: 'trade', width: 40},
      {headerTitle: getText('general:valueInMt', props), key: 'value', width: 35}
    ];

    const rows = [];
    let hasUnvalidatedData = false;
    tradeData.forEach(d => {
      d.data.forEach(dd => {
        if (!dd.ftwgValidated) {
          hasUnvalidatedData = true;
        }
        rows.push({
          year: dd.ftwgValidated ? dd.x : `${dd.x}*`,
          trade: d.id,
          value: dd.y,
          cellsStyles: {
            value: {
              alignment: {vertical: 'middle', horizontal: 'right'},
              numFmt: '#,##0.00'
            }
          }
        });
      })
    })

    return {
      'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', props)}` : null
    }
  }
}

const getAdditionalData = (country, additionalData, ftwgValidated) => {
  const rows = [];
  let total = 0;
  additionalData.data.forEach(additionalDataItem => {
    if (additionalDataItem.additionalData && additionalDataItem.additionalData.data.length > 0) {
      const returned = getAdditionalData(country, additionalDataItem.additionalData, ftwgValidated);
      rows.push(...returned.rows);
      total = total + returned.total;
    } else {
      total = total + (additionalDataItem.value * 1);
      rows.push({
        country: country,
        product: additionalDataItem.name,
        consumption: additionalDataItem.value,
        cellsStyles: {
          consumption: {
            alignment: { vertical: 'middle', horizontal: 'right' },
            numFmt: '#,##0.00'
          }
        }
      });
    }
  });
  return {rows, total};
}

export const resetLoadedData = () => {
  return (dispatch) => {
    dispatch({'type': TD_RESET_LOADED_DATA});
  }
}
// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [TD_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [TD_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [TD_LOAD_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [TD_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'data'], data).setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [TD_LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [TD_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [TD_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'loading'], false).setIn(['defaultSettings', 'data'], data);
  },
  [TD_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [TD_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [TD_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [TD_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [TD_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [TD_RESET_LOADED_DATA]: (state, action) => {
    return state.setIn(['defaultSettings', 'loaded'], false).setIn(['chartData', 'loaded'], false);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    years: {
      loaded: false, data: []
    },
    products: {
      loaded: false, data: []
    },
    defaults: {
      loaded: false, data: []
    }
  },
  filtersSelections: {
    productsSelected: [],
    years: [],
    countryIso: ''
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: {}
  },
  chartData: {
    loaded: false, 
    loading: false, 
    data: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
