import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';

const endpointMapping = {
  mapData: '/api/countries/vifaacountries'
}


// ------------------------------------ Constants ----------------------------------

const H_MAP_DATA_REQUEST = 'H_LOAD_MAP_DATA_REQUEST';
const H_MAP_DATA_SUCCESS = 'H_LOAD_MAP_DATA_SUCCESS';
const H_MAP_DATA_FAILURE = 'H_LOAD_MAP_DATA_FAILURE';

const H_LOAD_MAP_REQUEST = 'H_LOAD_MAP_REQUEST';
const H_LOAD_MAP_SUCCESS = 'H_LOAD_MAP_SUCCESS';
const H_LOAD_MAP_FAILURE = 'H_LOAD_MAP_FAILURE';


// ------------------------------------ Actions ------------------------------------

export const loadMapData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': H_MAP_DATA_REQUEST });
    apiConnector.getData(endpointMapping.mapData).then(data => {
      dispatch({ 'type': H_MAP_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': H_MAP_DATA_FAILURE, err });
    })
  }
}

export const loadMap = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': H_LOAD_MAP_REQUEST });
    apiConnector.getMap(getState().getIn(['homeMap', 'mapProps']).toJS()).then(data => {
      dispatch({ 'type': H_LOAD_MAP_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': H_LOAD_MAP_FAILURE, err });
    })
  }
}

const transformToGeoJson = (data) => {
  const features = [];
  data.forEach(d => {
    let properties = {};
    for (let key in d) {
      if (d.hasOwnProperty(key)) {
        if (key !== 'latitude' && key !== 'longitude') {
          properties[key] = d[key];
        }
      }
    }
    let geometry = {type: 'Point', coordinates: [d.longitude, d.latitude]}
    features.push({type: "Feature", properties, geometry});
  })
  return {type: 'FeatureCollection', features};
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [H_MAP_DATA_REQUEST]: (state, action) => {
    return state.setIn(['mapData', 'loading'], true);
  },
  [H_MAP_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['mapData', 'data'], data).setIn(['mapData', 'loading'], false).setIn(['mapData', 'loaded'], true);
  },
  [H_MAP_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['mapData', 'loading'], false).setIn(['loadingError'], err);
  },

  [H_LOAD_MAP_REQUEST]: (state, action) => {
    return state.setIn(['mapJson', 'loading'], true);
  },
  [H_LOAD_MAP_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['mapJson', 'data'], data.features).setIn(['mapJson', 'loading'], false).setIn(['mapJson', 'loaded'], true);
  },
  [H_LOAD_MAP_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['mapJson', 'loading'], false).setIn(['loadingError'], err);
  }
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  mapProps: {
    token: "public",
    service: 'WFS',
    version: "1.3",
    request: 'onEachFeature',
    format: "json",
    transparent: true,
    layers: "africa"
  },
  mapJson:{
    loaded: false,
    loading: false,
    data: null
  },
  mapData: {
    loaded: false,
    loading: false,
    data: []
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
