import React from 'react'
import asyncComponent from "../AsyncComponent";

import data from './reducers/data';
import embeddable from './reducers/embeddable';

const TabbedPosts = asyncComponent(() => import("./tabbedposts/"));
const PostsCarousel = asyncComponent(() => import("./postscarousel/"));
const PageGallery = asyncComponent(() => import("./pagegallery/"));
const PageModules = asyncComponent(() => import("./pagemodules/"));
const FeaturedTabs = asyncComponent(() => import("./featuredtabs/"));
const InlineList = asyncComponent(() => import("./inlinelist/"));
const Chart = asyncComponent(() => import("./chart/"));
const NewsLetter = asyncComponent(() => import("./newsletter/"));
const ShowcaseForm = asyncComponent(() => import("./showcase/"));
const HomeMap = asyncComponent(() => import("./homemap/"));
const PlantsDirectory = asyncComponent(() => import("./plantsDirectory/"));
const Filter = asyncComponent(() => import("./filter/"));
const Download = asyncComponent(() => import("./download/"));
const RetailPricesMap = asyncComponent(() => import("./retailPricesMap/"));
const RetailPrices = asyncComponent(() => import("./retailPrices/"));
const Stats = asyncComponent(() => import("./stats"));
const ImportsChart = asyncComponent(() => import("./importsChart"));
const TotalImportVolumesByCountryChart = asyncComponent(() => import("./totalImportVolumesByCountryChart"));
const TotalExportVolumesByCountryChart = asyncComponent(() => import("./totalExportVolumesByCountryChart"));
const ConsumptionChart = asyncComponent(() => import("./consumptionChart"));
const ConsumptionOverTimeChart = asyncComponent(() => import("./consumptionOverTimeChart"));
const FobPrices = asyncComponent(() => import("./fobPrices"));
const StayConnected = asyncComponent(() => import("./stayConnected"));
const HistoricalFobPrices = asyncComponent(() => import("./historicalFobPrices"));
const SingleProductPriceOverTime = asyncComponent(() => import("./singleProductPriceOverTime"));
const Resources = asyncComponent(() => import("./resources"));
const CountriesRetailPrices = asyncComponent(() => import("./countriesRetailPrices"));
const OtherCountries = asyncComponent(() => import("./otherCountries"));
const CountriesTradeChart = asyncComponent(() => import("./countriesTradeChart"));
const CountriesConsumptionChart = asyncComponent(() => import("./countriesConsumptionChart"));
const ExportsChart = asyncComponent(() => import("./exportsChart"));
const TopFertilizerCountries = asyncComponent(() => import("./topFertilizerCountries"));
const ProductionChart = asyncComponent(() => import("./productionChart"));
const TotalProductionVolumesByCountryChart = asyncComponent(() => import("./totalProductionVolumesByCountryChart"));
const PaginatedPosts = asyncComponent(() => import("./paginatedPosts"));
const Convertor = asyncComponent(() => import("./convertor"));
const ResourceView = asyncComponent(() => import("./resourceView"));

export const reducers = {
    data,
    embeddable
}


const components = {
    pageGallery: PageGallery,
    postsCarousel: PostsCarousel,
    chart: Chart,
    filter: Filter,
    showCaseForm: ShowcaseForm,
    newsLetter: NewsLetter,
    plantsDirectory: PlantsDirectory,
    homeMap: HomeMap,
    tabbedPosts: TabbedPosts,
    pageModules: PageModules,
    featuredTabs: FeaturedTabs,
    inlineList: InlineList,
    download: Download,
    retailPrices: RetailPrices,
    retailPricesMap: RetailPricesMap,
    fobPrices: FobPrices,
    stayConnected: StayConnected,
    historicalFobPrices: HistoricalFobPrices,
    stats: Stats,
    importsChart: ImportsChart,
    totalImportVolumesByCountryChart: TotalImportVolumesByCountryChart,
    totalExportVolumesByCountryChart: TotalExportVolumesByCountryChart,
    consumptionChart: ConsumptionChart,
    consumptionOverTimeChart: ConsumptionOverTimeChart,
    singleProductPriceOverTime: SingleProductPriceOverTime,
    resources: Resources,
    countriesRetailPrices: CountriesRetailPrices,
    otherCountries: OtherCountries,
    countriesTradeChart: CountriesTradeChart,
    countriesConsumptionChart: CountriesConsumptionChart,
    exportsChart: ExportsChart,
    topFertilizerCountries: TopFertilizerCountries,
    productionChart: ProductionChart,
    totalProductionVolumesByCountryChart: TotalProductionVolumesByCountryChart,
    paginatedPosts: PaginatedPosts,
    convertor: Convertor,
    resourceView: ResourceView
}

export const getComponentByNameIgnoreCase = (name) => {
    console.log("name" + name)
    const k = Object.keys(components).filter(value => value.toLowerCase() == name.toLowerCase())
    return components[k]
}
