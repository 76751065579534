import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
  filters: {
    regions: '/api/region/nonNimasaList',
    categories: '/api/plants/subCategories',
    countries: '/api/plants/countries',
    companies: '/api/plants/companies',
    defaults: '/api/filtersDefaults/plants'
  },
  chartData: '/api/plants',
  settings: '/api/configuration/plantDirectoryByCountryMap',
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const PD_LOAD_FILTER_LIST_REQUEST = 'PD_LOAD_FILTER_LIST_REQUEST';
const PD_LOAD_FILTER_LIST_SUCCESS = 'PD_LOAD_FILTER_LIST_SUCCESS';
const PD_LOAD_FILTER_LIST_FAILURE = 'PD_LOAD_FILTER_LIST_FAILURE';

const PD_LOAD_CHART_DATA_REQUEST = 'PD_LOAD_CHART_DATA_REQUEST';
const PD_LOAD_CHART_DATA_SUCCESS = 'PD_LOAD_CHART_DATA_SUCCESS';
const PD_LOAD_CHART_DATA_FAILURE = 'PD_LOAD_CHART_DATA_FAILURE';

const PD_LOAD_SETTINGS_REQUEST = 'PD_LOAD_SETTINGS_REQUEST';
const PD_LOAD_SETTINGS_SUCCESS = 'PD_LOAD_SETTINGS_SUCCESS';
const PD_LOAD_SETTINGS_FAILURE = 'PD_LOAD_SETTINGS_FAILURE';

const PD_LOAD_TRANSLATION_DATA_REQUEST = 'PD_LOAD_TRANSLATION_DATA_REQUEST';
const PD_LOAD_TRANSLATION_DATA_SUCCESS = 'PD_LOAD_TRANSLATION_DATA_SUCCESS';
const PD_LOAD_TRANSLATION_DATA_FAILURE = 'PD_LOAD_TRANSLATION_DATA_FAILURE';

const PD_CHANGE_FILTER_VALUE = 'PD_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'PD_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'PD_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'PD_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PD_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': PD_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': PD_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PD_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
      dispatch({ 'type': PD_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': PD_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['plantsDirectory', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PD_LOAD_CHART_DATA_REQUEST });
    const params = getState().getIn(['plantsDirectory', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({ 'type': PD_LOAD_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PD_LOAD_CHART_DATA_FAILURE, err });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PD_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': PD_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PD_LOAD_SETTINGS_FAILURE, err });
    });
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    const data = settings || getState().getIn(['plantsDirectory', 'defaultSettings', 'data']);
    const fullCountryList = getState().getIn(['plantsDirectory', 'filtersData', 'countries', 'data']);
    const regionType = data.regionType;
    const countries = fullCountryList.filter(c => c.regions.find(r => r.type.id === regionType.id) !== undefined)
        .map(c => ({...c, region: c.regions ? c.regions.find(r => r.type.id === regionType.id) : null}));
    const regions = getState().getIn(['plantsDirectory', 'filtersData', 'regions', 'data']);
    const regionData = regions.filter(r => r.type.id === regionType.id);
    dispatch({'type': PD_LOAD_FILTER_LIST_SUCCESS, data: regionData, filter: 'regions'});//clean regions list with setting
    dispatch({'type': PD_LOAD_FILTER_LIST_SUCCESS, data: countries, filter: 'countries'});
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'plantsDirectory', 'plantSubCategoriesSelected',
        'countriesSelected','regionsSelected', 'plantCompanySelected');
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('plantSubCategoriesSelected', [], false));
    dispatch(changeFilterValue('countriesSelected', [], false));
    dispatch(changeFilterValue('plantCompanySelected', [], false));
    dispatch(setDefaultFilters());
    dispatch(loadChartData());   
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PD_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

const transformToGeoJson = (data) => {
  const features = [];
  data.forEach(d => {
    let properties = {};
    for (let key in d) {
      if (d.hasOwnProperty(key)) {
        if (key !== 'latitude' && key !== 'longitude') {
          properties[key] = d[key];
        }
      }
    }
    let geometry = {type: 'Point', coordinates: [d.longitude, d.latitude]}
    features.push({type: "Feature", properties, geometry});
  })
  return {type: 'FeatureCollection', features};
}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const pdFilters = getState().getIn(['plantsDirectory', 'filtersSelections']);
    const plantTypes = getState().getIn(['plantsDirectory', 'filtersData', 'categories', 'data']);
    const plantsList = plantTypes.filter(i => pdFilters.get('plantSubCategoriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const countries = getState().getIn(['plantsDirectory', 'filtersData', 'countries', 'data']);
    const countriesList = countries.filter(i => pdFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const regions = getState().getIn(['plantsDirectory', 'filtersData', 'regions', 'data']);
    const regionsList = regions.filter(i => pdFilters.get('regionsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const companies = getState().getIn(['plantsDirectory', 'filtersData', 'companies', 'data']);
    const companiesList = companies.filter(i => pdFilters.get('plantCompanySelected').indexOf(i.id) !== -1).map(i => i.name);
    return [
      {name: getText('plantDirectory:plantTypes', props), options: plantsList},
      {name: getText('general:countries', props), options: countriesList.length === countries.length ? ['All'] : countriesList},
      {name: getText('general:regions', props), options: regionsList.length === regions.length ? ['All'] : regionsList},
      {name: getText('plantDirectory:companies', props), options: companiesList.length === companies.length ? ['All'] : companiesList},
    ];
  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const pdData = getState().getIn(['plantsDirectory', 'chartData']);
    const pdFilters = getState().getIn(['plantsDirectory', 'filtersSelections']);
    const plantTypes = getState().getIn(['plantsDirectory', 'filtersData', 'categories', 'data']);
    const plantsList = plantTypes.filter(i => pdFilters.get('plantSubCategoriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const countries = getState().getIn(['plantsDirectory', 'filtersData', 'countries', 'data']);
    const countriesList = countries.filter(i => pdFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const regions = getState().getIn(['plantsDirectory', 'filtersData', 'regions', 'data']);
    const regionsList = regions.filter(i => pdFilters.get('regionsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const companies = getState().getIn(['plantsDirectory', 'filtersData', 'companies', 'data']);
    const companiesList = companies.filter(i => pdFilters.get('plantCompanySelected').indexOf(i.id) !== -1).map(i => i.name);
    const defaultSettings = getState().getIn(['plantsDirectory', 'defaultSettings', 'data']);
    const filters = [
      {name: getText('plantDirectory:plantTypes', props), values: plantsList.join(', ')},
      {name: getText('general:countries', props), values: countriesList.length === countries.length ? 'All' : countriesList.join(', ')},
      {name: getText('general:regions', props), values: regionsList.length === regions.length ? 'All' : regionsList.join(', ')},
      {name: getText('plantDirectory:companies', props), values: companiesList.length === companies.length ? 'All' : companiesList.join(', ')},
    ];

    const columns = [
      {headerTitle: getText('plantDirectory:plant', props), key: 'name', width: 40},
      {headerTitle: getText('plantDirectory:parentCompany', props), key: 'parent', width: 40},
      {headerTitle: getText('plantDirectory:plantType', props), key: 'type', width: 40},
      {headerTitle: getText('plantDirectory:nameCapacityValue', props), key: 'capacity', width: 40},
      {headerTitle: getText('plantDirectory:nameCapacityUnits', props), key: 'unit', width: 40},
      {headerTitle: getText('plantDirectory:productsProduced', props), key: 'products', width: 40},
      {headerTitle: getText('plantDirectory:yearEstablished', props), key: 'year', width: 40},
      {headerTitle: getText('plantDirectory:latitude', props), key: 'latitude', width: 40},
      {headerTitle: getText('plantDirectory:longitude', props), key: 'longitude', width: 40},
      {headerTitle: getText('plantDirectory:city', props), key: 'city', width: 40},
      {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 40},
      {headerTitle: getText('plantDirectory:region', props), key: 'region', width: 40},
      {headerTitle: getText('plantDirectory:mainContact', props), key: 'contact', width: 40},
      {headerTitle: getText('plantDirectory:position', props), key: 'position', width: 40},
      {headerTitle: getText('plantDirectory:email', props), key: 'email', width: 40},
      {headerTitle: getText('plantDirectory:phone', props), key: 'phone', width: 40},
    ];

    const rows = [];
    let maxYear = 0;
    if (pdData.get('loaded')) {
      let data = pdData.get('data').features;
      data.forEach(d => {
        const {
          plantCategory, nameplateCapacityUnit, nameplateCapacityValue, name, nameplateCapacityValueMax,
          parentCompany, productsProduced, plantType, country, city, yearEstablished,
          mainContactName, mainContactPosition, mainContactEmail, mainContactPhone, modifiedYear
        } = d.properties;
        const [longitude, latitude] = d.geometry.coordinates;
        maxYear = Math.max(maxYear, (modifiedYear || 0));
        rows.push({
          name,
          parent: parentCompany,
          type: plantCategory.label,
          capacity: `${nameplateCapacityValue}${nameplateCapacityValueMax ? '-' + nameplateCapacityValueMax : ''}`,
          unit: nameplateCapacityUnit.label,
          products: productsProduced ? productsProduced.split('\n').join(', ') : 'N/A',
          year: yearEstablished,
          latitude,
          longitude,
          city,
          country: country.name,
          region: country.regions && country.regions.length ? country.regions.map(c => c.label).join(', ') : country.region,
          contact: mainContactName,
          position: mainContactPosition,
          email: mainContactEmail,
          phone: mainContactPhone,
          /*cellsStyles: {
            imports: {
              alignment: { vertical: 'middle', horizontal: 'right' },
              numFmt: '#,##0.00'
            }
          }*/
        });
      })
    }

    return {
      'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy,
      lastUpdate: maxYear
    }
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [PD_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    if (Array.isArray(data)) {
      data.forEach(d => {
        if (!d.name && d.label) {
          d.name = d.label;
        }
      });
    }
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [PD_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [PD_LOAD_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [PD_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    const dataGeoJson = transformToGeoJson(data);
    return state.setIn(['chartData', 'data'], dataGeoJson).setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [PD_LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PD_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [PD_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [PD_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [PD_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [PD_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [PD_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [PD_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    regions: {
      loaded: false, data: []
    },
    categories: {
      loaded: false,
      data: []
    },
    countries: {
      loaded: false,
      data: []
    },
    companies: {
      loaded: false,
      data: []
    }
  },
  filtersSelections: {
    plantSubCategoriesSelected: [],
    countriesSelected: [],
    regionsSelected: [],
    plantCompanySelected:[],
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: []
  },
  chartData: {
    loaded: false, 
    loading: false, 
    data: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
