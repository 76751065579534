import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import geoStats from 'geostats';
import {getText} from "../../utils/translationsUtil";
import {numericStyle, roundNumber} from "../../utils/numberUtils";
import {dispatchFilterDefaults} from "../../utils/chartUtils";
import {getMonthsShort} from "../../utils/dateUtils";
// ------------------------------------ Constants ----------------------------------
export const NRP_LOAD_FILTER_LIST_REQUEST = 'NRP_LOAD_FILTER_LIST_REQUEST';
export const NRP_LOAD_FILTER_LIST_SUCCESS = 'NRP_LOAD_FILTER_LIST_SUCCESS';
export const NRP_LOAD_FILTER_LIST_FAILURE = 'NRP_LOAD_FILTER_LIST_FAILURE';

export const NRP_LOAD_MAP_DATA_REQUEST = 'NRP_LOAD_MAP_DATA_REQUEST';
export const NRP_LOAD_MAP_DATA_SUCCESS = 'NRP_LOAD_MAP_DATA_SUCCESS';
export const NRP_LOAD_MAP_DATA_FAILURE = 'NRP_LOAD_MAP_DATA_FAILURE';

export const NRP_LOAD_SETTINGS_REQUEST = 'NRP_LOAD_SETTINGS_REQUEST';
export const NRP_LOAD_SETTINGS_SUCCESS = 'NRP_LOAD_SETTINGS_SUCCESS';
export const NRP_CHANGE_VIEW = 'NRP_CHANGE_VIEW';
export const NRP_LOAD_SETTINGS_FAILURE = 'NRP_LOAD_SETTINGS_FAILURE';

export const NRP_LOAD_TRANSLATION_DATA_REQUEST = 'NRP_LOAD_TRANSLATION_DATA_REQUEST';
export const NRP_LOAD_TRANSLATION_DATA_SUCCESS = 'NRP_LOAD_TRANSLATION_DATA_SUCCESS';
export const NRP_LOAD_TRANSLATION_DATA_FAILURE = 'NRP_LOAD_TRANSLATION_DATA_FAILURE';

export const NRP_CHANGE_FILTER_VALUE = 'NRP_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'NRP_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'NRP_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'NRP_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------
const endpointMapping = {
  filters: {
     regions: '/api/region/nonNimasaList',
     dates: '/api/prices/dates/all',
     defaults: '/api/filtersDefaults/prices/byProducts'
   },
  mapData: '/api/prices/nationalAvgPrices',
  settings: '/api/configuration/nationalPriceByCountryChart' ,
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': NRP_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': NRP_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': NRP_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': NRP_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch({ 'type': NRP_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': NRP_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['nationalRetailPrices', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': NRP_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      const fobProducts = data.fobProducts;
      dispatch({ 'type': NRP_LOAD_FILTER_LIST_SUCCESS, data: fobProducts, filter: 'fobProducts' });
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': NRP_LOAD_SETTINGS_SUCCESS, data });
      if(data.defaultPresentationType) {
        dispatch(changeView(data.defaultPresentationType.code));
      }
    }).catch(function(err) {
      dispatch({ 'type': NRP_LOAD_SETTINGS_FAILURE, err });
    });
  }
}

export const changeView = (view) => {
  return (dispatch, getState) => {
    dispatch({'type': NRP_CHANGE_VIEW, view : view});
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('regionSelected', 0, false));
    dispatch(changeFilterValue('dates', [], false));
    dispatch(setDefaultFilters());
    dispatch(loadMapData());
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    const data = settings || getState().getIn(['nationalRetailPrices', 'defaultSettings', 'data']);
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'nationalRetailPrices', 'unit', 'productSelected', 'regionSelected', 'dates', 'currencyCode');
    const productOriginsSelected = getState().getIn(['nationalRetailPrices', 'filtersData', 'fobProducts', 'data']);
    dispatch(changeFilterValue('productOriginsSelected', productOriginsSelected.map(p => p.id), false));
    if (data.regionType) {
      const regions = getState().getIn(['nationalRetailPrices', 'filtersData', 'regions', 'data']);
      const regionsFiltered = regions.filter(r => data.regionType.id === r.type.id);
      dispatch({'type': NRP_LOAD_FILTER_LIST_SUCCESS, data: regionsFiltered, filter: 'regions'});
    }
  }
}

export const loadMapData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': NRP_LOAD_MAP_DATA_REQUEST });
    const params = getState().getIn(['nationalRetailPrices', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.mapData, params).then(data => {
      dispatch({ 'type': NRP_LOAD_MAP_DATA_SUCCESS, data });
    }).catch(function (err) {
      dispatch({ 'type': NRP_LOAD_MAP_DATA_FAILURE, err });
    })
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': NRP_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadMapData());
    }
  }
}

const getDataConverted = (data, state) => {
  //const fobSelected = state.getIn(['filtersSelections', 'productOriginsSelected'])
  let nationalPrices = data.nationalPrices ? data.nationalPrices.slice() : [];
  let fobPrices = data.fobPrices ? data.fobPrices.slice() : [];
  //const fobFiltered = fobPrices.filter(fp => fobSelected.indexOf(parseInt(fp.code)) != -1);
  return {nationalPrices, fobPrices};

}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const regions = getState().getIn(['nationalRetailPrices', 'filtersData', 'regions']);
    const fobProducts = getState().getIn(['nationalRetailPrices', 'filtersData', 'fobProducts']);
    const priceFilters = getState().getIn(['nationalRetailPrices', 'filtersSelections']);
    const lang = getState().getIn(['intl', 'locale']);
    let month = '';
    if (priceFilters.get('dates').length) {
      month = `${getMonthsShort(lang)[parseInt(priceFilters.get('dates')[0].split('-')[1]) - 1]}-${priceFilters.get('dates')[0].split('-')[0]}`
    }
    const regionList = regions.get('data').filter(i => priceFilters.get('regionSelected') == i.id).map(i => i.name);
    const productList = fobProducts.get('data').filter(i => priceFilters.get('productOriginsSelected').indexOf(i.id) != -1).map(i => i.name);
    return [
      {name: getText('filter:month', props) , options: [month]},
      {name: getText('plantDirectory:region', props), options: regionList.length === 0 ? ['All'] : regionList},
      {name: getText('price:fobProduct', props)  || 'FOB Products', options: productList}
    ];
  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const priceData = getState().getIn(['nationalRetailPrices', 'mapData', 'convertedData']);
    const defaultSettings = getState().getIn(['nationalRetailPrices', 'defaultSettings', 'data']);
    const regions = getState().getIn(['nationalRetailPrices', 'filtersData', 'regions']);
    const priceFilters = getState().getIn(['nationalRetailPrices', 'filtersSelections']);
    const lang = getState().getIn(['intl', 'locale']);
    let month = '';
    if (priceFilters.get('dates').length) {
      month = `${getMonthsShort(lang)[parseInt(priceFilters.get('dates')[0].split('-')[1]) - 1]}-${priceFilters.get('dates')[0].split('-')[0]}`
    }
    const regionList = regions.get('data').filter(i => priceFilters.get('regionSelected') == i.id).map(i => i.name);
    const filters = [
      {name: getText('filter:month', props) , values: month},
      {name: getText('general:countries', props), values: regionList.length === 0 ? 'All' : regionList.join(', ')}
    ];
    const columns = [
      {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 40},
      {headerTitle: `${getText('price:retailPrice', props)} - ${priceFilters.get('unit')}`, key: 'retailPrice', width: 30},
      {headerTitle: `${getText('price:subsidizedPrice', props)} - ${priceFilters.get('unit')}`, key: 'subsidizedPrice', width: 30},
      {headerTitle: `${getText('price:fobPrice', props)} - ${priceFilters.get('unit')}`, key: 'fobPrice', width: 30}
    ];
    const rows = [];
    if (priceData.fobPrices.length) {
      priceData.fobPrices.forEach(loc => {
        if (loc.value) {
          rows.push({
            country: `World - ${loc.name}`,
            fobPrice: roundNumber(loc.value),
            cellsStyles: {
              fobPrice: numericStyle
            }
          });
        }
      })
    }
    if (priceData.nationalPrices.length) {
      priceData.nationalPrices.forEach(loc => {
        rows.push({
          country: loc.countryName,
          retailPrice: loc.retailValue ? roundNumber(loc.retailValue) : 'N/A',
          subsidizedPrice: loc.subsidizedValue ? roundNumber(loc.subsidizedValue) : 'N/A',
          cellsStyles: {
            retailPrice: numericStyle,
            subsidizedPrice: numericStyle
          }
        });
      })
    }
    return {'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy
    }
  }
}

const getBreaks = (data, prevRanges, unit) => {
  let values = [];
  let rangesParsed = [];
  let parsedData = data.map(d=> {
    return d.total.toFixed(2);
  });

  parsedData.forEach(item => {
    if (item > 0) {
      let floor = item * 0.99;
      let ceil = item * 1.01;
      if (values.indexOf(floor) === - 1) {
        values.push(floor);
      }
      if (values.indexOf(ceil) === - 1) {
        values.push(ceil);
      }
    }
  });

  if (values.length > 0) {
    const serie = new geoStats(values);
    serie.setColors(['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c']);
    serie.setPrecision(2);
    let numberOfRanges = values.length > 1 ? values.length -1: values.length;
    serie.getJenks(Math.min(5, numberOfRanges));
    serie.ranges.forEach((range) => {
      rangesParsed.push(parseFloat(range.substr(0, range.indexOf('-') - 1)));
      rangesParsed.push(parseFloat(range.substr(range.indexOf('-') + 2, range.length)));
    });
    if (rangesParsed.length > 2 && rangesParsed[0] === rangesParsed[1] && rangesParsed[0] === rangesParsed[2]) {
      rangesParsed = rangesParsed.slice(2);
    }
    return rangesParsed;
  } else {
    return prevRanges;
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [NRP_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const { data, filter } = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [NRP_LOAD_MAP_DATA_REQUEST]: (state, action) => {
    return state.setIn(['mapData', 'loading'], true);
  },
  [NRP_LOAD_MAP_DATA_SUCCESS]: (state, action) => {
    const { data } = action;
    const convertedData = getDataConverted(data, state);
    const legendData = convertedData.nationalPrices.map(d => { return { total: d.retailValue } });
    const legendRanges = getBreaks(legendData);
    return state.setIn(['mapData', 'data'], data).setIn(['mapData', 'convertedData'], convertedData).setIn(['mapData', 'legendRanges'], legendRanges)
      .setIn(['mapData', 'loading'], false).setIn(['mapData', 'loaded'], true);
  },
  [NRP_LOAD_MAP_DATA_FAILURE]: (state, action) => {
    const { err } = action;
    return state.setIn(['mapData', 'loading'], false).setIn(['loadingError'], err);
  },
  [NRP_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [NRP_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const { data } = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [NRP_CHANGE_VIEW]: (state, action) => {
    const {view} = action;
    return state.setIn(['view'], view);
  },
  [NRP_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const { err } = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [NRP_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [NRP_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
    .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [NRP_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [NRP_CHANGE_FILTER_VALUE]: (state, action) => {
    const { filter, value } = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    fobProducts: {
      loaded: false, data: []
    },
    regions: {
      loaded: false, data: []
    },
    dates: {
      loaded: false, data: []
    },
  },
  filtersSelections: {
    regionSelected: 0,
    productSelected: 0,
    productOriginsSelected:[],
    dates: ['2010-01-01', '2020-12-31'],
    currencyCode: '',
    unit: ''
  },
  defaultSettings: {
    loaded: false,
    loading: false,
    data: []
  },
  bounds: {
    center: [0.189103, 37.8455004],
    zoom: 6
  },
  mapData: {
    loaded: false,
    loading: false,
    data: [],
    legendRanges: [],
    convertedData: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  selectedLanguage: 'en',
  translationData: {
    loaded: false, 
    loading: false, 
    data: {
      defaultLanguage: 'en',
      translations: {}
    }   
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
