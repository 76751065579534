import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {dispatchFilterDefaults} from "../../utils/chartUtils";


// ------------------------------------ Constants ----------------------------------
export const HIST_FOB_LOAD_FILTER_LIST_REQUEST = 'HIST_FOB_LOAD_FILTER_LIST_REQUEST';
export const HIST_FOB_LOAD_FILTER_LIST_SUCCESS = 'HIST_FOB_LOAD_FILTER_LIST_SUCCESS';
export const HIST_FOB_LOAD_FILTER_LIST_FAILURE = 'HIST_FOB_LOAD_FILTER_LIST_FAILURE';

export const HIST_FOB_LOAD_CHART_DATA_REQUEST = 'HIST_FOB_LOAD_CHART_DATA_REQUEST';
export const HIST_FOB_LOAD_CHART_DATA_SUCCESS = 'HIST_FOB_LOAD_CHART_DATA_SUCCESS';
export const HIST_FOB_LOAD_CHART_DATA_FAILURE = 'HIST_FOB_LOAD_CHART_DATA_FAILURE';

export const HIST_FOB_LOAD_SETTINGS_REQUEST = 'HIST_FOB_LOAD_SETTINGS_REQUEST';
export const HIST_FOB_LOAD_SETTINGS_SUCCESS = 'HIST_FOB_LOAD_SETTINGS_SUCCESS';
export const HIST_FOB_LOAD_SETTINGS_FAILURE = 'HIST_FOB_LOAD_SETTINGS_FAILURE';

export const HIST_FOB_LOAD_ARGUS_LEGEND_REQUEST = 'HIST_FOB_LOAD_ARGUS_LEGEND_REQUEST';
export const HIST_FOB_LOAD_ARGUS_LEGEND_SUCCESS = 'HIST_FOB_LOAD_ARGUS_LEGEND_SUCCESS';
export const HIST_FOB_LOAD_ARGUS_LEGEND_FAILURE = 'HIST_FOB_LOAD_ARGUS_LEGEND_FAILURE';

export const HIST_FOB_LOAD_TRANSLATION_DATA_REQUEST = 'HIST_FOB_LOAD_TRANSLATION_DATA_REQUEST';
export const HIST_FOB_LOAD_TRANSLATION_DATA_SUCCESS = 'HIST_FOB_LOAD_TRANSLATION_DATA_SUCCESS';
export const HIST_FOB_LOAD_TRANSLATION_DATA_FAILURE = 'HIST_FOB_LOAD_TRANSLATION_DATA_FAILURE';

export const HIST_FOB_CHANGE_FILTER_VALUE = 'HIST_FOB_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'HIST_FOB_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'HIST_FOB_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'HIST_FOB_LOAD_NO_DATA_TEXT_FAILURE';

const endpointMapping = {
    filters: {
        products: '/api/fob/originsList',
        defaults: '/api/filtersDefaults/fob/historicalSeriesByProducts',
    },
    translations:'/api/dashboardTranslation/all',
    chartData: '/api/fob/historicalSeriesByProducts',
    settings: '/api/configuration/historicalIntlPricesChart',
    noDataText: '/api/settings/noData',
    argusLegend: '/api/fob/argusLegend'
}

// ------------------------------------ Actions ------------------------------------

export const loadTranslationsData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_LOAD_TRANSLATION_DATA_REQUEST, filter });
        apiConnector.getData(endpointMapping.translations).then(data => {
            dispatch({ 'type': HIST_FOB_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': HIST_FOB_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
        })
    }
}

export const loadNoDataText = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
        apiConnector.getData(endpointMapping.noDataText).then(data => {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
        }).catch(function(err) {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
        })
    }
}

export const loadFilterData = (filter) => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_LOAD_FILTER_LIST_REQUEST, filter });
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
            dispatch({ 'type': HIST_FOB_LOAD_FILTER_LIST_SUCCESS, data, filter });
        }).catch(function (err) {
            dispatch({ 'type': HIST_FOB_LOAD_FILTER_LIST_FAILURE, err, filter });
        })
    }
}

export const loadFilterDataIfNotLoaded = (filter) => {
    return (dispatch, getState) => {
        if(!getState().getIn(['historicalFobPrices', 'filtersData', filter, 'loaded'])) {
            dispatch(loadFilterData(filter));
        }
    }
}

export const loadChartData = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_LOAD_CHART_DATA_REQUEST });
        const params = getState().getIn(['historicalFobPrices', 'filtersSelections']).toJS();
        params.lang = getState().getIn(['intl', 'locale']);
        apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
            dispatch({ 'type': HIST_FOB_LOAD_CHART_DATA_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': HIST_FOB_LOAD_CHART_DATA_FAILURE, err });
        })
    }
}

export const loadDefaultSettings = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_LOAD_SETTINGS_REQUEST });
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
            dispatch(setDefaultFilters(data));
            dispatch({ 'type': HIST_FOB_LOAD_SETTINGS_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': HIST_FOB_LOAD_SETTINGS_FAILURE, err });
        })
    }
}

export const resetFilters = (chart) => {
    return (dispatch, getState) => {
        dispatch(setDefaultFilters());
        dispatch(loadChartData());
    }
}

const setDefaultFilters = (settings) => {
    return (dispatch, getState) => {
        const data = settings || getState().getIn(['historicalFobPrices', 'defaultSettings', 'data']);
        dispatch({ 'type': HIST_FOB_LOAD_FILTER_LIST_SUCCESS, data: data.fobProducts, filter: 'products' });
        dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'historicalFobPrices', 'productOriginsSelected', 'unit', 'currencyCode');
    }
}


export const loadArgusLegend = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_LOAD_ARGUS_LEGEND_REQUEST });
        apiConnector.getData(endpointMapping.argusLegend).then(data => {
            dispatch({ 'type': HIST_FOB_LOAD_ARGUS_LEGEND_SUCCESS, data });
        }).catch(function(err) {
            dispatch({ 'type': HIST_FOB_LOAD_ARGUS_LEGEND_FAILURE, err });
        })
    }
}


export const changeFilterValue = (filter, value, reload) => {
    return (dispatch, getState) => {
        dispatch({ 'type': HIST_FOB_CHANGE_FILTER_VALUE, filter, value });
        if (reload) {
            dispatch(loadChartData());
        }
    }
}


export const getFiltersForPrint = () => {
    return (dispatch, getState) => {
        const products = getState().getIn(['historicalFobPrices', 'filtersData', 'products']);
        const fobFilters = getState().getIn(['historicalFobPrices', 'filtersSelections']);
        const selectedLanguage = getState().getIn(['historicalFobPrices', 'selectedLanguage']);
        const translationData = getState().getIn(['historicalFobPrices', 'translationData']).toJS().data;
        const productsList = products.get('data').filter(i => fobFilters.get('productOriginsSelected').indexOf(i.id) !== -1).map(i => i.name);
        return [
            {name: getText('general:products', {selectedLanguage, translationData}), options: productsList},
        ];
    }
}


// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
    [HIST_FOB_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
        const {data, filter} = action;
        return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
    },
    [HIST_FOB_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['loadingError'], err);
    },
    [HIST_FOB_LOAD_CHART_DATA_REQUEST]: (state, action) => {
        return state.setIn(['chartData', 'loading'], true);
    },
    [HIST_FOB_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['chartData', 'data'], data)
            .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
    },
    [HIST_FOB_LOAD_CHART_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
    },
    [HIST_FOB_LOAD_SETTINGS_REQUEST]: (state, action) => {
        return state.setIn(['defaultSettings', 'loading'], true);
    },
    [HIST_FOB_LOAD_SETTINGS_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
    },
    [HIST_FOB_LOAD_SETTINGS_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
    },
    [HIST_FOB_LOAD_ARGUS_LEGEND_REQUEST]: (state, action) => {
        return state.setIn(['argusLegend', 'loading'], true);
    },
    [HIST_FOB_LOAD_ARGUS_LEGEND_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['argusLegend', 'loaded'], true).setIn(['argusLegend', 'data'], data);
    },
    [HIST_FOB_LOAD_ARGUS_LEGEND_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['argusLegend', 'loading'], false).setIn(['loadingError'], err);
    },
    [HIST_FOB_CHANGE_FILTER_VALUE]: (state, action) => {
        const {filter, value} = action;
        return state.setIn(['filtersSelections', filter], value);
    },
    [HIST_FOB_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
        return state.setIn(['translationData', 'loading'], true);
    },
    [HIST_FOB_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['translationData', 'data'], data)
            .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
    },
    [HIST_FOB_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
    },
    [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
        return state.setIn(['noDataText', 'loading'], true);
    },
    [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['noDataText', 'data'], data)
          .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
    },
    [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
    },

};


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
    filtersData: {
        defaults: {
            loaded: false, data: []
        },
        dates: {
            loaded: false, data: []
        },
        products: {
            loaded: false, data: []
        }
    },
    filtersSelections: {
        productOriginsSelected: [],

        dates: [],
        unit: '',
        currencyCode: ''
    },
    defaultSettings: {
        loaded: false,
        loading: false,
        data: []
    },
    noDataText: {
        loaded: false,
        loading: false,
        data: {}
    },
    translationData: {
        loaded: false,
        loading: false,
        data: {
            defaultLanguage: 'en',
            translations: {}
        }
    },
    argusLegend: {
        loaded: false,
        loading: false,
        data: {}
    },
    chartData: {
        loaded: false,
        loading: false,
        data: [], convertedData: {}
    }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
}
