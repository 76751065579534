import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
  filters: {
    years: '/api/production/regional/yearsList',
    countries: '/api/production/regional/countriesList',
    products: '/api/production/regional/productsList',
    defaults: '/api/filtersDefaults/production/regional/byCountry'
  },
  chartData: '/api/production/regional/byCountry',
  settings: '/api/configuration/annualProductionVolumesByCountry',
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const PC_LOAD_FILTER_LIST_REQUEST = 'PC_LOAD_FILTER_LIST_REQUEST';
const PC_LOAD_FILTER_LIST_SUCCESS = 'PC_LOAD_FILTER_LIST_SUCCESS';
const PC_LOAD_FILTER_LIST_FAILURE = 'PC_LOAD_FILTER_LIST_FAILURE';

const PC_LOAD_CHART_DATA_REQUEST = 'PC_LOAD_CHART_DATA_REQUEST';
const PC_LOAD_CHART_DATA_SUCCESS = 'PC_LOAD_CHART_DATA_SUCCESS';
const PC_LOAD_CHART_DATA_FAILURE = 'PC_LOAD_CHART_DATA_FAILURE';

const PC_LOAD_SETTINGS_REQUEST = 'PC_LOAD_SETTINGS_REQUEST';
const PC_LOAD_SETTINGS_SUCCESS = 'PC_LOAD_SETTINGS_SUCCESS';
const PC_LOAD_SETTINGS_FAILURE = 'PC_LOAD_SETTINGS_FAILURE';

const PC_LOAD_TRANSLATION_DATA_REQUEST = 'PC_LOAD_TRANSLATION_DATA_REQUEST';
const PC_LOAD_TRANSLATION_DATA_SUCCESS = 'PC_LOAD_TRANSLATION_DATA_SUCCESS';
const PC_LOAD_TRANSLATION_DATA_FAILURE = 'PC_LOAD_TRANSLATION_DATA_FAILURE';

const PC_CHANGE_FILTER_VALUE = 'PC_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'PC_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'PC_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'PC_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PC_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': PC_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': PC_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PC_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
      dispatch({ 'type': PC_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': PC_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['production', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PC_LOAD_CHART_DATA_REQUEST });
    const params = getState().getIn(['production', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({ 'type': PC_LOAD_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PC_LOAD_CHART_DATA_FAILURE, err });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': PC_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': PC_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PC_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'production', 'yearSelected', 'productsSelected', 'countriesSelected');
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(setDefaultFilters(getState().getIn(['production', 'defaultSettings', 'data'])));
    dispatch(loadChartData());
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': PC_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const importFilters = getState().getIn(['production', 'filtersSelections']);
    const importData = getState().getIn(['production', 'chartData', 'data']);
    const years = getState().getIn(['production', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => importFilters.get('yearSelected') === i);
    const countries = getState().getIn(['production', 'filtersData', 'countries', 'data']);
    const products = getState().getIn(['production', 'filtersData', 'products', 'data']);
    const countriesList = countries.filter(i => importFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const productsList = products.filter(i => importFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const retValue = [
      {name: getText('filter:year', props), options: yearList},
      {name: getText('general:countries', props), options: countriesList},
      {name: getText('general:products', props), options: productsList},
    ];
    let hasUnvalidatedData = importData.filter(d => d.ftwgValidated === false).length > 0;
    if (hasUnvalidatedData) {
      retValue.unshift({name: getText('general:note', props), options:[`* ${getText('use:notValidated', props)}`], isSettings: true})
    }
    return retValue;
  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const productionFilters = getState().getIn(['production', 'filtersSelections']);
    const productionData = getState().getIn(['production', 'chartData', 'data']);
    const defaultSettings = getState().getIn(['production', 'defaultSettings', 'data']);
    const years = getState().getIn(['production', 'filtersData', 'years', 'data']);
    const yearList = years.filter(i => productionFilters.get('yearSelected') === i);
    const countries = getState().getIn(['production', 'filtersData', 'countries', 'data']);
    const countriesList = countries.filter(i => productionFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
    const products = getState().getIn(['production', 'filtersData', 'products', 'data']);
    const productsList = products.filter(i => productionFilters.get('productsSelected').indexOf(i.id) !== -1).map(i => i.name);
    const filters = [
      {name: getText('filter:year', props), values: yearList.join(', ')},
      {name: getText('general:countries', props), values: countriesList.join(', ')},
      {name: getText('general:products', props), values: productsList.join(', ')},
    ];

    const columns = [
      {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 40},
      {headerTitle: getText('general:product', props), key: 'product', width: 40},
      {headerTitle: getText('general:productionInMt', props), key: 'production', width: 15}
    ];

    const rows = [];
    let hasUnvalidatedData = false;
    productionData.forEach(d => {
      const {country, ftwgValidated, products} = d;
      if (!ftwgValidated) {
        hasUnvalidatedData = true;
      }
      products.forEach(p => {
        if (p.additionalData && p.additionalData.data && p.additionalData.data.length > 0) {
          const returned = getAdditionalData(country.name, p.additionalData, d.ftwgValidated);
          rows.push(...returned.rows);
        } else {
          if (p.value) {
            rows.push({
              country: ftwgValidated ? country.name : `${country.name}*`,
              product: p.name,
              production: p.value,
              cellsStyles: {
                production: {
                  alignment: {vertical: 'middle', horizontal: 'right'},
                  numFmt: '#,##0.00'
                }
              }
            });
          }
        }
      })
    })

    return {
      'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', props)}` : null
    }
  }
}

const getAdditionalData = (country, additionalData, ftwgValidated) => {
  const rows = [];
  let total = 0;
  additionalData.data.forEach(additionalDataItem => {
    if (additionalDataItem.additionalData && additionalDataItem.additionalData.data.length > 0) {
      const returned = getAdditionalData(country, additionalDataItem.additionalData, ftwgValidated);
      rows.push(...returned.rows);
      total = total + returned.total;
    } else {
      total = total + (additionalDataItem.value * 1);
      if (additionalDataItem.value) {
        rows.push({
          country: ftwgValidated ? country : `${country}*`,
          product: additionalDataItem.name,
          production: additionalDataItem.value,
          cellsStyles: {
            production: {
              alignment: {vertical: 'middle', horizontal: 'right'},
              numFmt: '#,##0.00'
            }
          }
        });
      }
    }
  });
  return {rows, total};
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [PC_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [PC_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [PC_LOAD_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [PC_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'data'], data)
      .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [PC_LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PC_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [PC_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [PC_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [PC_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [PC_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [PC_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [PC_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
    countries: {
      loaded: false,
      data: []
    },
    products: {
      loaded: false,
      data: []
    },
  },
  filtersSelections: {
    yearSelected: null,
    countriesSelected: [],
    productsSelected: [],
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: {}
  },
  chartData: {
    loaded: false, 
    loading: false, 
    data: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
