import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {NUTRIENTS} from "../../utils/constants";
import {roundNumber, numericStyle} from "../../utils/numberUtils";
import {dataDownloadStyles} from "../../utils/exportUtils";
import {getText} from '../../utils/translationsUtil';
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
  filters: {
    years: '/api/consumption/years',
    nutrients: '/api/consumption/nutrients',
    defaults: '/api/filtersDefaults/consumption/ApparentConsumption'
  },
  chartData: '/api/consumption/regional/consumptionByNutrient',
  settings: '/api/configuration/apparentConsumptionChart',
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
export const BY_NUTRIENTS_FILTER_LIST_REQUEST = 'BY_NUTRIENTS_LOAD_FILTER_LIST_REQUEST';
export const BY_NUTRIENTS_FILTER_LIST_SUCCESS = 'BY_NUTRIENTS_LOAD_FILTER_LIST_SUCCESS';
export const BY_NUTRIENTS_FILTER_LIST_FAILURE = 'BY_NUTRIENTS_LOAD_FILTER_LIST_FAILURE';

export const BY_NUTRIENTS_CHART_DATA_REQUEST = 'BY_NUTRIENTS_LOAD_CHART_DATA_REQUEST';
export const BY_NUTRIENTS_CHART_DATA_SUCCESS = 'BY_NUTRIENTS_LOAD_CHART_DATA_SUCCESS';
export const BY_NUTRIENTS_CHART_DATA_FAILURE = 'BY_NUTRIENTS_LOAD_CHART_DATA_FAILURE';
export const BY_NUTRIENTS_FILTER_CHART_DATA = 'BY_NUTRIENTS_FILTER_CHART_DATA';

export const BY_NUTRIENTS_SETTINGS_REQUEST = 'BY_NUTRIENTS_LOAD_SETTINGS_REQUEST';
export const BY_NUTRIENTS_SETTINGS_SUCCESS = 'BY_NUTRIENTS_LOAD_SETTINGS_SUCCESS';
export const BY_NUTRIENTS_SETTINGS_FAILURE = 'BY_NUTRIENTS_LOAD_SETTINGS_FAILURE';

export const BY_NUTRIENTS_CHANGE_FILTER_VALUE = 'BY_NUTRIENTS_CHANGE_FILTER_VALUE';

export const BY_NUTRIENTS_TRANSLATION_DATA_REQUEST = 'BY_NUTRIENTS_TRANSLATION_DATA_REQUEST';
export const BY_NUTRIENTS_TRANSLATION_DATA_SUCCESS = 'BY_NUTRIENTS_TRANSLATION_DATA_SUCCESS';
export const BY_NUTRIENTS_TRANSLATION_DATA_FAILURE = 'BY_NUTRIENTS_TRANSLATION_DATA_FAILURE';

export const BY_NUTRIENTS_COUNTRY_CHANGED = 'BY_NUTRIENTS_COUNTRY_CHANGED';

export const BY_NUTRIENTS_CHANGE_VIEW = 'BY_NUTRIENTS_CHANGE_VIEW';
const BY_NUTRIENTS_RESET_LOADED_DATA = 'BY_NUTRIENTS_RESET_LOADED_DATA';


const LOAD_NO_DATA_TEXT_REQUEST = 'BN_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'BN_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'BN_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}


export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesByNutrients', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': BY_NUTRIENTS_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': BY_NUTRIENTS_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': BY_NUTRIENTS_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['countriesByNutrients', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': BY_NUTRIENTS_CHART_DATA_REQUEST });
    const params = getState().getIn(['countriesByNutrients', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
      dispatch({ 'type': BY_NUTRIENTS_CHART_DATA_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': BY_NUTRIENTS_CHART_DATA_FAILURE, err });
    })
  }
}

export const onCountryChange = (country) => {
  return (dispatch, getState) => {
    dispatch({ 'type': BY_NUTRIENTS_COUNTRY_CHANGED, country });
  }
}

export const onApplyNutrientsFilter = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': BY_NUTRIENTS_FILTER_CHART_DATA });
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesByNutrients', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': BY_NUTRIENTS_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch(setDefaultFilters(data));
      if(data.defaultConsumptionByType) {
        dispatch(changeView(data.defaultConsumptionByType.code));
      }
      dispatch({ 'type': BY_NUTRIENTS_SETTINGS_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': BY_NUTRIENTS_SETTINGS_FAILURE, err });
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['countriesByNutrients', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': BY_NUTRIENTS_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(`${endpointMapping.translations}?countryIso=${countryIso}`).then(data => {
      dispatch({ 'type': BY_NUTRIENTS_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': BY_NUTRIENTS_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'countriesByNutrients', 'nutrients', 'years');
  }
}

export const resetFilters = (reload) => {
  return (dispatch, getState) => {    
  dispatch(changeFilterValue('nutrients', [], false));
  dispatch(changeFilterValue('years', [], false));
    if (reload) {
      dispatch(setDefaultFilters());
      dispatch(loadChartData());
    }
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': BY_NUTRIENTS_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadChartData());
    }
  }
}

export const changeView = (view) => {
  return (dispatch, getState) => {
    dispatch({ 'type': BY_NUTRIENTS_CHANGE_VIEW, view });
  }
}

export const getFiltersForPrint = () => {
  return (dispatch, getState) => {
    const nafcnData = getState().getIn(['countriesByNutrients', 'chartData']);
    const filters = getState().getIn(['countriesByNutrients', 'filtersSelections']);
    const selectedLanguage = getState().getIn(['countriesByNutrients', 'selectedLanguage']);
    const translationData = getState().getIn(['countriesByNutrients', 'translationData']).toJS().data;
    const hasUnvalidatedData = nafcnData.some(s => !s.ftwgValidated);
    return hasUnvalidatedData ? [{
      name: getText('general:note', {selectedLanguage, translationData}),
      options: [`* ${getText('use:notValidated', {selectedLanguage, translationData})}`],
      isSettings: true
    }] : [];
  }
}

export const createExportData = () => {
  return (dispatch, getState) => {
    const nafcnFilters = getState().getIn(['countriesByNutrients', 'filtersSelections']);
    const nafcnData = getState().getIn(['countriesByNutrients', 'chartData']);
    const settings = getState().getIn(['countriesByNutrients', 'defaultSettings', 'data']);
    const selectedLanguage = getState().getIn(['countriesByNutrients', 'selectedLanguage']);
    const translationData = getState().getIn(['countriesByNutrients', 'translationData']).toJS().data;
    let hasUnvalidatedData = false;
    const expFilters = [
      {name: getText('filter:yearS', {selectedLanguage, translationData}), values: nafcnFilters.get('years').join(', ')},
    ];

    const columns = [
      {headerTitle: getText('filter:year', {selectedLanguage, translationData}), key: 'year', width: 25},
      {headerTitle: getText('use:nutrients', {selectedLanguage, translationData}), key: 'nutrients', width: 40},
      {headerTitle: getText('use:averageFertilizerUseInMtHa', {selectedLanguage, translationData}), key: 'averageConsumption', width: 30}
    ];
    const rows = [];


    nafcnData.sort((a, b) => {
      if (a.period < b.period) return -1;
      if (a.period > b.period) return 1;
      return 0;
    });

    let periods = []
    nafcnData.get('data').forEach(d => {
      if (periods.indexOf(d.year) === -1) {
        periods.push(d.year);
      }
    });

    periods.forEach(period => {
      let dataForPeriod = nafcnData.get('data').find(d => d.year === period).nutrients;
      let ftwgValidated = nafcnData.get('data').filter(d => d.year === period).some(s => s.ftwgValidated);
      hasUnvalidatedData = hasUnvalidatedData || !ftwgValidated;
      let totalForPeriod = 0;
      dataForPeriod.forEach(d => {
        totalForPeriod += d.value;
        rows.push({
          year: ftwgValidated ? period : period + '*',
          nutrients: d.name,
          averageConsumption: roundNumber(d.value),
          cellsStyles: {
            year: {
              alignment: {vertical: 'middle', horizontal: 'center'}
            },
            averageConsumption: numericStyle
          }
        });
      });
      
      const totalNumber = Object.assign({}, dataDownloadStyles.totalNumber);
      totalNumber.numFmt = '#,##0.00';
      rows.push({
        year: ftwgValidated ? period : period + '*',
        nutrients: getText('use:total', {selectedLanguage, translationData}),
        averageConsumption: roundNumber(totalForPeriod),
        cellsStyles: {
          year: {
            alignment: {vertical: 'middle', horizontal: 'center'},
            font: {
              name: 'Calibri',
              family: 4,
              bold: true
            },
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: '00d9ead3'
              }
            }
          },
          nutrients: dataDownloadStyles.totalLabels,
          averageConsumption: totalNumber
        }
      });

    })

    return {
      'title': settings.customChartName, filters: expFilters, columns, rows,
      source: settings.source,
      analysisBy: settings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', {selectedLanguage, translationData})}` : null
    }
  }
}

export const resetLoadedData = () => {
  return (dispatch) => {
    dispatch({'type': BY_NUTRIENTS_RESET_LOADED_DATA});
  }
}
// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [BY_NUTRIENTS_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [BY_NUTRIENTS_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [BY_NUTRIENTS_CHART_DATA_REQUEST]: (state, action) => {    
    return state.setIn(['chartData', 'loading'], true);
  },
  [BY_NUTRIENTS_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'data'], data)
      .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [BY_NUTRIENTS_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
  [BY_NUTRIENTS_FILTER_CHART_DATA]: (state, action) => {
    return state.setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
  },
  [BY_NUTRIENTS_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [BY_NUTRIENTS_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'loading'], false).setIn(['defaultSettings', 'data'], data);
  },
  [BY_NUTRIENTS_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [BY_NUTRIENTS_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [BY_NUTRIENTS_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [BY_NUTRIENTS_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [BY_NUTRIENTS_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [BY_NUTRIENTS_COUNTRY_CHANGED]: (state, action) => {
    const {country} = action;
    return state.setIn(['filtersSelections', 'countryIso'], country);
  },
  [BY_NUTRIENTS_CHANGE_VIEW]: (state, action) => {
    const {view} = action;
    return state.setIn(['view'], view);
  },
  [BY_NUTRIENTS_RESET_LOADED_DATA]: (state, action) => {
    return state.setIn(['defaultSettings', 'loaded'], false).setIn(['chartData', 'loaded'], false);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  view: null,
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false,
      data: [],
    },
    nutrients: {
      loaded: false,
      data: [],
    },
  },
  filtersSelections: {   
    nutrients: [], 
    years: [],
    unit: 'byCroplandArea',
    countryIso:'',
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: []
  },
  chartData: {
    loaded: false, 
    loading: false, 
    data: [], 
    filteredData: {}
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
