export const OTHERS = 'Others';
export const NPK = 'NPK';

export const CONSUMPTION_TYPES = {
	NUTRIENT_TON: 'nutrientTon',
	PRODUCT_TON: 'productTon'
};

export const PRESENTATION_TYPES = {
	LINE_CHART: 'lineChart',
	MAP: 'map',
	INFO_TABLE: 'infoTable',
	PIE_CHART: 'pieChart',
	BAR_CHART: 'barChart',
	TABLE: 'table'
};

export const DATES_TYPES = {
	MONTHS: 'months',
	YEARS: 'years',
	PERIODS: 'periods'
}

export const NUTRIENTS = [{ id: "Nitrogen (N)", name: "Nitrogen (N)", code: "NITROGEN" },
{ id: "Phosphorus (P2O5)", name: "Phosphorus (P2O5)", code: "PHOSPHOROUS" },
{ id: "Potassium (K2O)", name: "Potassium (K2O)", code: "POTASSIUM" }];

export const MONTHLY_VIEW = 'monthly';
export const YEARLY_VIEW = 'yearly';
export const COUNTRY_LINKS = {
	GH: 'ghana',
	NG: 'nigeria',
	KE: 'kenya',
	SN: 'senegal',
	ZM: 'zambia',
	MW: 'malawi',
	ET: 'ethiopia',
	MZ: 'mozambique'
}
export const VIFAA_COUNTRIES = {
	GHANA: 'GH',
	NIGERIA: 'NG',
	KENYA: 'KE',
	SENEGAL: 'SN',
	ZAMBIA: 'ZM',
	MALAWI: 'MW',
	ETHIOPIA: 'ET',
	MOZAMBIQUE: 'MZ'
}

export const COUNTRY_CENTROIDS = {
	BJ: { longitude: 1.43, latitude: 9.43 }, // Benin
	BF: { longitude: -2.9, latitude: 12 }, // Burkina Faso
	BI: { longitude: 29.9188854, latitude: -3.37305556 }, // Burundi
	CI: { longitude: -5.54708, latitude: 7.539989 }, // Côte d'Ivoire
	GH: { longitude: -1.21677, latitude: 7.953456 }, // Ghana
	KE: { longitude: 37.9155074, latitude: 0.6314842 }, // Kenya
	ML: { longitude: -3.9961665, latitude: 17.570692 }, // Mali
	MW: { longitude: 34.301525, latitude: -13.254308 }, // Malawi
	MZ: { longitude: 35.90, latitude: -18.66 }, // Mozambique
	NE: { longitude: 8.081666, latitude: 17.607789 }, // Niger
	NG: { longitude: 8.08943895, latitude: 9.59411452 }, // Nigeria
	RW: { longitude: 30.0588045, latitude: -1.9542595 }, // Rwanda
	SN: { longitude: -15.6380957, latitude: 14.476273 }, // Senegal
	TG: { longitude: 1.11546, latitude: 8.61954 }, // Togo
	TZ: { longitude: 34.8888222, latitude: -6.3690275 }, // Tanzania
	UG: { longitude: 32.290275, latitude: 1.373333 }, // Uganda
	ZM: { longitude: 27.8541907, latitude: -13.1403509 }, // Zambia
};

export const getCentroidByCountryCode = (countryCode) => {
	return [COUNTRY_CENTROIDS[countryCode].latitude, COUNTRY_CENTROIDS[countryCode].longitude]
}
export const COLORS = ['#629228', '#F7B811', '#C17AC2', '#FE4D4D', '#FFD822',
    '#F78A7C', '#90CCF9', '#2B9FF6', '#3375B8', '#FFB5AC', '#EBEC8A', '#C0C0C0',
    '#800000', '#008080', '#FF00FF'];

export const VIFAA_COLORS = [
	'#06529F',
	'#43740A',
	'#C58A01',
	'#C00909',
	'#840086',
	'#3375B8',
	'#629328',
	'#FFB811',
	'#FE4D4D',	
	'#D35400',
	'#2B9FF6',
	'#8FC44F',
	'#FFD822',
	'#FF8A7C',
	'#C17AC2',
	'#90CCF9',
	'#BFEC8A',
	'#EBED21',
	'#FFB5AC',
	'#FDFF56',
  '#40f290',
];

export const COMMERCIAL_AVG_PRICE = 'Commercial Average';
export const SUBSIDIZED_AVG_PRICE = 'Subsidy Average';