export const updateWithCompoundIds = (data, text) => {
  const dataUpdated = JSON.parse(JSON.stringify(data));
  dataUpdated.forEach(d => {
    d.id = d.compoundId;
    if (d.compoundId.indexOf('pfi') !== -1) {
      d.name = `${d.name} (${text || 'Subsidy'})`;
      d.label = d.label ? `${d.label} (${text || 'Subsidy'})` : d.name;
    }
  });
  return dataUpdated;
}

export const getChartUnitsFromSettingsData = (defaultSettingsData) => {
  if (defaultSettingsData) {
    if(defaultSettingsData.chartUnits) {
      return defaultSettingsData.chartUnits;
    } else {
      return defaultSettingsData.dashboard.chartUnits;
    }
  } else {
    return null;
  }
}

export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data));
}

export const getChartUnitsFromSettings = (defaultSettings) => {
  if (defaultSettings && defaultSettings.get('loaded')) {
    if(defaultSettings.getIn(['data', 'chartUnits'])) {
      return defaultSettings.getIn(['data', 'chartUnits']);
    } else {
      return defaultSettings.getIn(['data', 'dashboard', 'chartUnits']);
    }
  } else {
    return null;
  }
}