import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {dispatchFilterDefaults} from "../../utils/chartUtils";

const endpointMapping = {
    filters: {
      products:'/api/convertor/products',
    },
    settings: '/api/configuration/convertor',
    translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const CV_LOAD_FILTER_LIST_REQUEST = 'CV_LOAD_FILTER_LIST_REQUEST';
const CV_LOAD_FILTER_LIST_SUCCESS = 'CV_LOAD_FILTER_LIST_SUCCESS';
const CV_LOAD_FILTER_LIST_FAILURE = 'CV_LOAD_FILTER_LIST_FAILURE';

const CV_LOAD_SETTINGS_REQUEST = 'CV_LOAD_SETTINGS_REQUEST';
const CV_LOAD_SETTINGS_SUCCESS = 'CV_LOAD_SETTINGS_SUCCESS';
const CV_LOAD_SETTINGS_FAILURE = 'CV_LOAD_SETTINGS_FAILURE';

const CV_LOAD_TRANSLATION_DATA_REQUEST = 'CV_LOAD_TRANSLATION_DATA_REQUEST';
const CV_LOAD_TRANSLATION_DATA_SUCCESS = 'CV_LOAD_TRANSLATION_DATA_SUCCESS';
const CV_LOAD_TRANSLATION_DATA_FAILURE = 'CV_LOAD_TRANSLATION_DATA_FAILURE';

const CV_CHANGE_FILTER_VALUE = 'CV_CHANGE_FILTER_VALUE';

// ------------------------------------ Actions ------------------------------------

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CV_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': CV_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': CV_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['convertor', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CV_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch({ 'type': CV_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': CV_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': CV_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=NONE`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': CV_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': CV_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'convertor', 'productsSelected', 'countriesSelected', 'yearSelected');
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(setDefaultFilters(getState().getIn(['convertor', 'defaultSettings', 'data'])));
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CV_CHANGE_FILTER_VALUE, filter, value });
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [CV_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [CV_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [CV_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [CV_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [CV_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [CV_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [CV_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [CV_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [CV_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  }
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    products: {
      loaded: false,
      data: []
    },
  },
  filtersSelections: {
    productSelected: 0,
  },
  defaultSettings: {
    loaded: false, 
    loading: false, 
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
