import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";

const endpointMapping = {
  filters: {
    types: '/api/resource/types',
    authors: '/api/resource/authors',
    years: '/api/resource/years',
    languages: '/api/resource/languages'
  },
  resourceList: '/api/resource/list/active',
  translations: '/api/dashboardTranslation/all'
}


// ------------------------------------ Constants ----------------------------------
const RESOURCES_LOAD_FILTER_LIST_REQUEST = 'RESOURCES_LOAD_FILTER_LIST_REQUEST';
const RESOURCES_LOAD_FILTER_LIST_SUCCESS = 'RESOURCES_LOAD_FILTER_LIST_SUCCESS';
const RESOURCES_LOAD_FILTER_LIST_FAILURE = 'RESOURCES_LOAD_FILTER_LIST_FAILURE';

const RESOURCES_LOAD_LIST_REQUEST = 'RESOURCES_LOAD_LIST_REQUEST';
const RESOURCES_LOAD_LIST_SUCCESS = 'RESOURCES_LOAD_LIST_SUCCESS';
const RESOURCES_LOAD_LIST_FAILURE = 'RESOURCES_LOAD_LIST_FAILURE';

const RESOURCES_LOAD_TRANSLATION_DATA_REQUEST = 'RESOURCES_LOAD_TRANSLATION_DATA_REQUEST';
const RESOURCES_LOAD_TRANSLATION_DATA_SUCCESS = 'RESOURCES_LOAD_TRANSLATION_DATA_SUCCESS';
const RESOURCES_LOAD_TRANSLATION_DATA_FAILURE = 'RESOURCES_LOAD_TRANSLATION_DATA_FAILURE';

const RESOURCES_CHANGE_FILTER_VALUE = 'RESOURCES_CHANGE_FILTER_VALUE';

// ------------------------------------ Actions ------------------------------------

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': RESOURCES_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': RESOURCES_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': RESOURCES_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': RESOURCES_LOAD_FILTER_LIST_REQUEST, filter });
    apiConnector.getData(endpointMapping.filters[filter]).then(data => {
      dispatch({ 'type': RESOURCES_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function(err) {
      dispatch({ 'type': RESOURCES_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadList = (list) => {
  return (dispatch, getState) => {
    dispatch({ 'type': RESOURCES_LOAD_LIST_REQUEST, list });
    const params = getState().getIn(['resources', 'filtersSelections']).toJS();
    apiConnector.getDataWithParams(endpointMapping.resourceList, params).then(data => {
      dispatch({ 'type': RESOURCES_LOAD_LIST_SUCCESS, list, data });
    }).catch(function(err) {
      dispatch({ 'type': RESOURCES_LOAD_LIST_FAILURE, list, err });
    })
  }
}

export const setDefaultFilters = (settings) => {
  /*return (dispatch, getState) => {
    const types = getState().getIn(['resources', 'filtersData', 'types', 'data']);
    const authors = getState().getIn(['resources', 'filtersData', 'authors', 'data']);
    const years = getState().getIn(['resources', 'filtersData', 'years', 'data']);
    const languages = getState().getIn(['resources', 'filtersData', 'languages', 'data']);

    dispatch(changeFilterValue('typeSelected', types.map(p => p.id), false));
    dispatch(changeFilterValue('authorSelected', authors.map(p => p.id), false));
    dispatch(changeFilterValue('yearSelected', years.map(p => p.id), false));
    dispatch(changeFilterValue('languagesSelected', languages.map(p => p.id), false));
  }*/
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('typeSelected', 0, false));
    dispatch(changeFilterValue('authorSelected', 0, false));
    dispatch(changeFilterValue('yearSelected', 0, false));
    dispatch(changeFilterValue('languagesSelected', 0, false));
    dispatch(setDefaultFilters());
    dispatch(loadList('resourceList'));
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': RESOURCES_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadList('resourceList'));
    }
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [RESOURCES_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    data.forEach(d => {
      if (!d.name && d.label) {
        d.name = d.label;
      }
    });
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [RESOURCES_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['loadingError'], err);
  },
  [RESOURCES_LOAD_LIST_REQUEST]: (state, action) => {
    const {list} = action;
    return state.setIn([list, 'loading'], true);
  },
  [RESOURCES_LOAD_LIST_SUCCESS]: (state, action) => {
    const {list, data} = action;
    return state.setIn([list, 'data'], data).setIn([list, 'loading'], false).setIn([list, 'loaded'], true);
  },
  [RESOURCES_LOAD_LIST_FAILURE]: (state, action) => {
    const {list, err} = action;
    return state.setIn([list, 'loading'], false).setIn(['loadingError'], err);
  },
  [RESOURCES_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [RESOURCES_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [RESOURCES_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [RESOURCES_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  filtersData: {
    types: {
      loaded: false, data: []
    },
    authors: {
      loaded: false,
      data: []
    },
    years: {
      loaded: false,
      data: []
    },
    languages: {
      loaded: false,
      data: []
    }
  },
  filtersSelections: {
    typeSelected: 0,
    authorSelected: 0,
    yearSelected: 0,
    languageSelected: 0,
    keyword: '',
  },
  resourceList: {
    loaded: false,
    loading: false,
    data: []
  },
  topList: {
    loaded: false,
    loading: false,
    data: []
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
