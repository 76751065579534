export const getText = (key, props) => {
  let  {selectedLanguage, translationData, intl} = props;
  let result = null;
  if (translationData && translationData.translations) {
      if (selectedLanguage && translationData.translations[selectedLanguage]) {
        result = translationData.translations[selectedLanguage][key];
      } else if (intl && translationData.translations[intl.locale]){
        result = translationData.translations[intl.locale][key];
      }

      if  (!result && translationData.translations[translationData.defaultLanguage]) {
        result = translationData.translations[translationData.defaultLanguage][key]
      }
  }
  return result || '';
}

export const getTranslatedConfig = (configData, key, props) => {
  let {selectedLanguage, intl} = props;
  let locale = intl ? intl.locale : selectedLanguage;
  let result;
  if (configData && configData[`${key}Trn`] && configData[`${key}Trn`].entries) {
    const langEntries = configData[`${key}Trn`].entries;
    const langText = langEntries.find(l => l.language.code === locale);
    if (langText) {
      result = langText.text;
    } else {
      result = configData[key];
    }
  } else {
    result = configData[key];
  }
  return result;
}


export const languageChanged = (prevProps, props) => {
  return prevProps.selectedLanguage !== props.selectedLanguage;
}
