import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {dispatchFilterDefaults} from "../../utils/chartUtils";
import {MONTHLY_VIEW} from "../../utils/constants";
import {getBreaks} from "../../utils/mapUtils";
import {getMonthsShort} from "../../utils/dateUtils";
import {getText, getTranslatedConfig} from '../../utils/translationsUtil';
import {numericStyle, roundNumber} from "../../utils/numberUtils";
import {loadChartData, setDefaultFilters} from "../countriesConsumptionChart/apparentConsumptionModule";
// ------------------------------------ Constants ----------------------------------
export const PRICE_MAP_LOAD_FILTER_LIST_REQUEST = 'PRICE_MAP_LOAD_FILTER_LIST_REQUEST';
export const PRICE_MAP_LOAD_FILTER_LIST_SUCCESS = 'PRICE_MAP_LOAD_FILTER_LIST_SUCCESS';
export const PRICE_MAP_LOAD_FILTER_LIST_FAILURE = 'PRICE_MAP_LOAD_FILTER_LIST_FAILURE';
export const PRICE_MAP_LOAD_MAP_DATA_REQUEST = 'PRICE_MAP_LOAD_MAP_DATA_REQUEST';
export const PRICE_MAP_LOAD_MAP_DATA_SUCCESS = 'PRICE_MAP_LOAD_MAP_DATA_SUCCESS';
export const PRICE_MAP_LOAD_MAP_DATA_FAILURE = 'PRICE_MAP_LOAD_MAP_DATA_FAILURE';
export const PRICE_MAP_LOAD_SETTINGS_REQUEST = 'PRICE_MAP_LOAD_SETTINGS_REQUEST';
export const PRICE_MAP_LOAD_SETTINGS_SUCCESS = 'PRICE_MAP_LOAD_SETTINGS_SUCCESS';
export const PRICE_MAP_LOAD_SETTINGS_FAILURE = 'PRICE_MAP_LOAD_SETTINGS_FAILURE';
export const PRICE_MAP_LOAD_TRANSLATION_DATA_REQUEST = 'PRICE_MAP_LOAD_TRANSLATION_DATA_REQUEST';
export const PRICE_MAP_LOAD_TRANSLATION_DATA_SUCCESS = 'PRICE_MAP_LOAD_TRANSLATION_DATA_SUCCESS';
export const PRICE_MAP_LOAD_TRANSLATION_DATA_FAILURE = 'PRICE_MAP_LOAD_TRANSLATION_DATA_FAILURE';
export const PRICE_MAP_RESET_LOADED_DATA = 'PRICE_MAP_RESET_LOADED_DATA';

export const PRICE_MAP_CHANGE_FILTER_VALUE = 'PRICE_MAP_CHANGE_FILTER_VALUE';

export const PRICE_MAP_CHANGE_CENTER_VALUE = 'PRICE_MAP_CHANGE_CENTER_VALUE';

const PRICE_MAP_LOAD_NO_DATA_TEXT_REQUEST = 'PRICE_MAP_LOAD_NO_DATA_TEXT_REQUEST';
const PRICE_MAP_LOAD_NO_DATA_TEXT_SUCCESS = 'PRICE_MAP_LOAD_NO_DATA_TEXT_SUCCESS';
const PRICE_MAP_LOAD_NO_DATA_TEXT_FAILURE = 'PRICE_MAP_LOAD_NO_DATA_TEXT_FAILURE';

// ------------------------------------ Actions ------------------------------------
const endpointMapping = {
  filters: {
    products: '/api/prices/compoundProductsList',
    years: '/api/prices/yearsList',
    dates: '/api/prices/datesList',
    locations: '/api/prices/locations',
    defaults: '/api/filtersDefaults/prices/byProducts'
  },
  mapData: {
    yearly: '/api/prices/byProducts',
    monthly: '/api/prices/byProductsAndDates'
  },
  noDataText: '/api/settings/noData',
  settings: '/api/configuration/retailPriceChart',
  translations: '/api/dashboardTranslation/all'
}


export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({'type': PRICE_MAP_LOAD_NO_DATA_TEXT_REQUEST});
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({'type': PRICE_MAP_LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': PRICE_MAP_LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({'type': PRICE_MAP_LOAD_TRANSLATION_DATA_REQUEST, filter});
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({'type': PRICE_MAP_LOAD_TRANSLATION_DATA_SUCCESS, data, filter});
    }).catch(function (err) {
      dispatch({'type': PRICE_MAP_LOAD_TRANSLATION_DATA_FAILURE, err, filter});
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['retailPriceMap', 'filtersSelections', 'countryIso']);
    dispatch({'type': PRICE_MAP_LOAD_FILTER_LIST_REQUEST, filter});
    const lang = getState().getIn(['intl', 'locale']);
      apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
        dispatch({'type': PRICE_MAP_LOAD_FILTER_LIST_SUCCESS, data, filter});
      }).catch(function (err) {
        dispatch({'type': PRICE_MAP_LOAD_FILTER_LIST_FAILURE, err, filter});
      })
    }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if (!getState().getIn(['retailPriceMap', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    const countryIso = getState().getIn(['retailPriceMap', 'filtersSelections', 'countryIso']);
    dispatch({ 'type': PRICE_MAP_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}&countryIso=${countryIso}`).then(data => {
      dispatch(setSettings(data));
      dispatch({ 'type': PRICE_MAP_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': PRICE_MAP_LOAD_SETTINGS_FAILURE, err });
    })
  }
}

export const changeView = (view) => {
  return (dispatch, getState) => {
    changeFilterValue('timePeriod', view, true)(dispatch, getState);
  }
}

export const resetFilters = (reload) => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('townsSelected', [], false));
    dispatch(changeFilterValue('dates', [], false));
    dispatch(changeFilterValue('unit', '', false));
    dispatch(changeFilterValue('yearSelected', 0, false));
    dispatch(changeFilterValue('compoundProductSelected', 0, false));
    dispatch(changeFilterValue('currencyCode', '', false));
    if (reload) {
      dispatch(setSettings());
      dispatch(loadMapData());
    }
  }
}


export const loadMapData = () => {
  return (dispatch, getState) => {
    dispatch({'type': PRICE_MAP_LOAD_MAP_DATA_REQUEST});
    const params = getState().getIn(['retailPriceMap', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.mapData.yearly, params).then(data => {
      dispatch({'type': PRICE_MAP_LOAD_MAP_DATA_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': PRICE_MAP_LOAD_MAP_DATA_FAILURE, err});
    })
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({'type': PRICE_MAP_CHANGE_FILTER_VALUE, filter, value});
    if (filter === 'unit') { //if unit changes, it force to change the currency value
      dispatch({ 'type': PRICE_MAP_CHANGE_FILTER_VALUE, filter: 'currencyCode', value: value.split('_')[0] });
    }
    if (filter === 'compoundProductsSelected') {
      dispatch({ 'type': PRICE_MAP_CHANGE_FILTER_VALUE, filter: 'compoundProductSelected', value: value[0] });
    }
    if (filter === 'years') {
      dispatch({ 'type': PRICE_MAP_CHANGE_FILTER_VALUE, filter: 'yearSelected', value: value[0] });
    }
    if (filter === 'defaultTimeSeries') {
      dispatch({ 'type': PRICE_MAP_CHANGE_FILTER_VALUE, filter: 'timePeriod', value: value });
    }
    if (reload) {
      dispatch(loadMapData());
    }
  }
}

export const changeMapCenter = (value) => {
  return (dispatch, getState) => {
    dispatch({'type': PRICE_MAP_CHANGE_CENTER_VALUE, value});
  }
}

const setSettings = (settings) => {
  return (dispatch, getState) => {
    dispatchFilterDefaults(changeFilterValue, dispatch, getState,  'retailPriceMap', 'currencyCode',
      'unit', 'townsSelected', 'years', 'dates', 'compoundProductsSelected', 'defaultTimeSeries');
  }
}

 export const convertData = (data) => {
  const dataValues = data.filter(d => d.value).map(d => Object.assign(d, {total: d.value}));
  const legendRanges = getBreaks(dataValues,5);
  return {legendRanges, data: dataValues};
}

export const resetLoadedData = () => {
  return (dispatch) => {
    dispatch({'type': PRICE_MAP_RESET_LOADED_DATA});
  }
}

export const getFiltersForPrint = () => {
  return (dispatch, getState) => {
    const locations = getState().getIn(['retailPriceMap', 'filtersData', 'locations']);
    const products = getState().getIn(['retailPriceMap', 'filtersData', 'products']);
    const frpFilters = getState().getIn(['retailPriceMap', 'filtersSelections']);
    const timePeriod = getState().getIn(['retailPriceMap', 'timePeriod']);
    const selectedLanguage = getState().getIn(['retailPriceMap', 'selectedLanguage']);
    const translationData = getState().getIn(['retailPriceMap', 'translationData']).toJS().data;
    const year = frpFilters.get('yearSelected');
    let month = '';
    if (frpFilters.get('dates').length) {
      month = `${getMonthsShort(selectedLanguage)[parseInt(frpFilters.get('dates')[0].split('-')[1]) - 1]}-${frpFilters.get('dates')[0].split('-')[0]}`
    }
    const productsList = products.get('data').filter(i => frpFilters.get('compoundProductSelected') === i.id).map(i => i.name);
    const locationList = locations.get('data').filter(i => frpFilters.get('townsSelected').indexOf(i.id) !== -1).map(i => i.vifaaName);
    return [
      {name: timePeriod === MONTHLY_VIEW ? getText('filter:month', {selectedLanguage, translationData}) :getText('filter:years', {selectedLanguage, translationData}), options: timePeriod === 'monthly' ? [month] : [year]},
      {name: getText('general:products', {selectedLanguage, translationData}), options: productsList},
      {name: getText('general:locations', {selectedLanguage, translationData}), options: locationList.length === locations.get('data').length ? [] : locationList}
    ];
  }
}

export const createExportData = () => {
  return (dispatch, getState) => {
    const selectedLanguage = getState().getIn(['retailPriceMap', 'selectedLanguage']);
    const translationData = getState().getIn(['retailPriceMap', 'translationData']).toJS().data;
    const data = getState().getIn(['retailPriceMap', 'mapData', 'convertedData']);
    const locations = getState().getIn(['retailPriceMap', 'filtersData', 'locations']);
    const products = getState().getIn(['retailPriceMap', 'filtersData', 'products']);
    const frpFilters = getState().getIn(['retailPriceMap', 'filtersSelections']);
    const timePeriod = getState().getIn(['retailPriceMap', 'timePeriod']);
    const settings = getState().getIn(['retailPriceMap', 'defaultSettings', 'data']);
    const year = frpFilters.get('yearSelected').toString();
    let month = '';
    if (frpFilters.get('dates').length) {
      month = `${getMonthsShort(selectedLanguage)[parseInt(frpFilters.get('dates')[0].split('-')[1]) - 1]}-${frpFilters.get('dates')[0].split('-')[0]}`
    }
    const productsList = products.get('data').filter(i => frpFilters.get('compoundProductSelected') === i.id).map(i => getTranslatedConfig(i, 'name', {selectedLanguage, translationData}));
    const locationList = locations.get('data').filter(i => frpFilters.get('townsSelected').indexOf(i.id) !== -1).map(i => getTranslatedConfig(i, 'name', {selectedLanguage, translationData}));
    const filters = [
      {name: timePeriod === MONTHLY_VIEW ? getText('filter:month', {selectedLanguage, translationData}) : getText('filter:year', {selectedLanguage, translationData}), values: timePeriod === MONTHLY_VIEW ? month : year},
      {name: getText('general:products', {selectedLanguage, translationData}), values: productsList.join(', ')},
      {name: getText('general:locations', {selectedLanguage, translationData}), values: locationList.length === locations.length ? '' : locationList.join(', ')}
    ];
    const columns = [
      {headerTitle: getText('general:location', {selectedLanguage, translationData}), key: 'location', width: 40},
      {headerTitle: `${getText('general:openMarketRetail', {selectedLanguage, translationData})} - ${frpFilters.get('unit')}`, key: 'price', width: 30}
    ];
    const rows = [];
    const retailData = data;
    if (retailData.length) {
      retailData.forEach(loc => {
        if (loc.value) {
          rows.push({
            location: `${getTranslatedConfig(loc.locationName, 'vifaaName', {selectedLanguage, translationData})} (${loc.adminOneName})`,
            price: roundNumber(loc.value),
            cellsStyles: {
              price: numericStyle
            }
          });
        }
      })
    }
    return {'title': settings.customChartName, filters, columns, rows,
      source: settings.source,
      analysisBy: settings.analysisBy
    }
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [PRICE_MAP_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const {data, filter} = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [PRICE_MAP_LOAD_MAP_DATA_REQUEST]: (state, action) => {
    return state.setIn(['mapData', 'loading'], true);
  },
  [PRICE_MAP_LOAD_MAP_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    let convertedData=[];
    convertedData = convertData(data, state);
    return state.setIn(['mapData', 'data'], data).setIn(['mapData', 'convertedData'], convertedData.data)
      .setIn(['mapData', 'legendRanges'], convertedData.legendRanges)
     .setIn(['mapData', 'loading'], false).setIn(['mapData', 'loaded'], true);

  },
  [PRICE_MAP_LOAD_MAP_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['mapData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICE_MAP_RESET_LOADED_DATA]: (state, action) => {
    return state.setIn(['defaultSettings', 'loaded'], false).setIn(['defaultSettings', 'loading'], false)
      .setIn(['mapData', 'loaded'], false);
  },
  [PRICE_MAP_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [PRICE_MAP_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [PRICE_MAP_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICE_MAP_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [PRICE_MAP_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [PRICE_MAP_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [PRICE_MAP_CHANGE_FILTER_VALUE]: (state, action) => {
    const {filter, value} = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [PRICE_MAP_CHANGE_CENTER_VALUE]: (state, action) => {
    const {value} = action;
    return state.setIn(['mapSettings', 'center'], value);
  },
  [PRICE_MAP_LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [PRICE_MAP_LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [PRICE_MAP_LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}


// ------------------------------------ Reducer ------------------------------------
export const initialState = Immutable.fromJS({
  loadingError: false,
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
    products: {
      loaded: false, data: []
    },
    locations: {
      loaded: false, data: []
    },
    dates: {
      loaded: false, data: []
    }
  },
  filtersSelections: {
    townsSelected: [],
    compoundProductSelected: 0,
    yearSelected: 0,
    years: [],
    zoomLevel: 7,
    unit: '',
    currencyCode: '',
    countryIso:'',
    dates: [],
    timePeriod: '',
  },
  defaultSettings: {
    loaded: false,
    loading: false,
    data: []
  },
  mapData: {
    legendRanges: [],
    data: {},
    convertedData: [],
    loaded: false,
    loading: false,
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  },
  selectedLanguage: 'en',
  mapSettings: {
    center: [],
    zoomSnap: 0.25,
    zoom: 6,
    minZoom: 6,
    maxZoom: 14
  },
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
