import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';

const endpointMapping = {
  chartData: '/api/region/othersCountries',
}

// ------------------------------------ Constants ----------------------------------
export const LOAD_DATA_REQUEST = 'LOAD_DATA_REQUEST';
export const LOAD_CHART_DATA_SUCCESS = 'LOAD_CHART_DATA_SUCCESS';
export const LOAD_CHART_DATA_FAILURE = 'LOAD_CHART_DATA_FAILURE';

// ------------------------------------ Actions ------------------------------------
export const loadChartData = () => {
  return (dispatch, getState) => {
    dispatch({'type': LOAD_DATA_REQUEST});
    apiConnector.getData(endpointMapping.chartData).then(data => {
      dispatch({'type': LOAD_CHART_DATA_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': LOAD_CHART_DATA_FAILURE, err});
    })
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_CHART_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['data', 'data'], data)
      .setIn(['chartData', 'loading'], false)
      .setIn(['chartData', 'loaded'], true);
  },
  [LOAD_DATA_REQUEST]: (state, action) => {
    const {data} = action;
    return state.setIn(['chartData', 'loading'], true)

  },
  [LOAD_CHART_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  data: {
    loaded: false,
    loading: false,
    data: []
  }
})

export default function appReducer(state = initialState, action) {
  if (action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
  } else {
    return state;
  }
}