import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import geoStats from 'geostats';
import {getText} from "../../utils/translationsUtil";
import {numericStyle, roundNumber} from "../../utils/numberUtils";
import {dispatchFilterDefaults} from "../../utils/chartUtils";
// ------------------------------------ Constants ----------------------------------
export const TFC_LOAD_FILTER_LIST_REQUEST = 'TFC_LOAD_FILTER_LIST_REQUEST';
export const TFC_LOAD_FILTER_LIST_SUCCESS = 'TFC_LOAD_FILTER_LIST_SUCCESS';
export const TFC_LOAD_FILTER_LIST_FAILURE = 'TFC_LOAD_FILTER_LIST_FAILURE';

export const TFC_LOAD_MAP_DATA_REQUEST = 'TFC_LOAD_MAP_DATA_REQUEST';
export const TFC_LOAD_MAP_DATA_SUCCESS = 'TFC_LOAD_MAP_DATA_SUCCESS';
export const TFC_LOAD_MAP_DATA_FAILURE = 'TFC_LOAD_MAP_DATA_FAILURE';

export const TFC_LOAD_SETTINGS_REQUEST = 'TFC_LOAD_SETTINGS_REQUEST';
export const TFC_LOAD_SETTINGS_SUCCESS = 'TFC_LOAD_SETTINGS_SUCCESS';
export const TFC_LOAD_SETTINGS_FAILURE = 'TFC_LOAD_SETTINGS_FAILURE';

export const TFC_LOAD_TRANSLATION_DATA_REQUEST = 'TFC_LOAD_TRANSLATION_DATA_REQUEST';
export const TFC_LOAD_TRANSLATION_DATA_SUCCESS = 'TFC_LOAD_TRANSLATION_DATA_SUCCESS';
export const TFC_LOAD_TRANSLATION_DATA_FAILURE = 'TFC_LOAD_TRANSLATION_DATA_FAILURE';

export const TFC_CHANGE_FILTER_VALUE = 'TFC_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'TFC_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'TFC_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'TFC_LOAD_NO_DATA_TEXT_FAILURE';

const endpointMapping = {
  filters: {
     countries: '/api/indicators/countries',
     years: '/api/indicators/yearsList',
     defaults: '/api/filtersDefaults/indicators/byCountry'
  },
  mapData: '/api/indicators/byCountry',
  settings: '/api/configuration/topFertilizerCountries' ,
  noDataText: '/api/settings/noData',
  translations: '/api/dashboardTranslation/all'
}

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
    apiConnector.getData(endpointMapping.noDataText).then(data => {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TFC_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': TFC_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': TFC_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

export const loadFilterData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TFC_LOAD_FILTER_LIST_REQUEST, filter });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
      dispatch({ 'type': TFC_LOAD_FILTER_LIST_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': TFC_LOAD_FILTER_LIST_FAILURE, err, filter });
    })
  }
}

export const loadFilterDataIfNotLoaded = (filter) => {
  return (dispatch, getState) => {
    if(!getState().getIn(['topFertilizerCountries', 'filtersData', filter, 'loaded'])) {
      dispatch(loadFilterData(filter));
    }
  }
}

export const loadDefaultSettings = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': TFC_LOAD_SETTINGS_REQUEST });
    const lang = getState().getIn(['intl', 'locale']);
    apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
      dispatch(setDefaultFilters(data));
      dispatch({ 'type': TFC_LOAD_SETTINGS_SUCCESS, data });
    }).catch(function(err) {
      dispatch({ 'type': TFC_LOAD_SETTINGS_FAILURE, err });
    });
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    dispatch(changeFilterValue('countriesSelected', [], false));
    dispatch(changeFilterValue('yearSelected', 0, false));
    dispatch(setDefaultFilters());
    dispatch(loadMapData());
  }
}

export const setDefaultFilters = (settings) => {
  return (dispatch, getState) => {
    const data = settings || getState().getIn(['topFertilizerCountries', 'defaultSettings', 'data']);
    dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'topFertilizerCountries', 'countriesSelected', 'yearSelected');
    const defaultIndicator = data.defaultDatasetType.code;
    dispatch(changeFilterValue('indicator', defaultIndicator, false));
    if (data.regionType) {
      const countries = getState().getIn(['topFertilizerCountries', 'filtersData', 'countries', 'data']);
      const countriesWithRegionsFiltered = countries.map(c => {
        c.region = c.regions.find(r => data.regionType.id === r.type.id);
        return c;
      });
      dispatch({'type': TFC_LOAD_FILTER_LIST_SUCCESS, data: countriesWithRegionsFiltered, filter: 'countries'});
    }
  }
}

export const loadMapData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': TFC_LOAD_MAP_DATA_REQUEST });
    const params = getState().getIn(['topFertilizerCountries', 'filtersSelections']).toJS();
    params.lang = getState().getIn(['intl', 'locale']);
    apiConnector.getDataWithParams(endpointMapping.mapData, params).then(data => {
      dispatch({ 'type': TFC_LOAD_MAP_DATA_SUCCESS, data });
    }).catch(function (err) {
      dispatch({ 'type': TFC_LOAD_MAP_DATA_FAILURE, err });
    })
  }
}

export const changeFilterValue = (filter, value, reload) => {
  return (dispatch, getState) => {
    dispatch({ 'type': TFC_CHANGE_FILTER_VALUE, filter, value });
    if (reload) {
      dispatch(loadMapData());
    }
  }
}

const getDataConverted = (data) => {
  const newData = data.slice();
  const importsValues = newData.filter(cd => cd.imports).map(cd => cd.imports.totalYear);
  const exportsValues = newData.filter(cd => cd.exports).map(cd => cd.exports.totalYear);
  const productionValues = newData.filter(cd => cd.production).map(cd => cd.production.totalYear);
  const consumptionValues = newData.filter(cd => cd.consumption).map(cd => cd.consumption.totalYear);
  newData.legendRanges = {};
  newData.legendRanges.imports = getBreaks(importsValues);
  newData.legendRanges.exports = getBreaks(exportsValues);
  newData.legendRanges.production = getBreaks(productionValues);
  newData.legendRanges.consumption = getBreaks(consumptionValues);
  return newData;
}

export const getUnvalidatedData = (data) => {
  let validated = false
  data.every(d => {
      if (d.consumption && d.consumption.ftwgValidated === false) {
        validated = true;
        return false;
      } else if (d.imports && d.imports.ftwgValidated === false) {
        validated = true;
        return false;
      } else if (d.exports && d.exports.ftwgValidated === false) {
        validated = true;
        return false;
      } else if (d.production && d.production.ftwgValidated === false) {
        validated = true;
        return false;
      }
  });
  return validated;
}

export const getFiltersForPrint = (props) => {
  return (dispatch, getState) => {
    const filters = getState().getIn(['topFertilizerCountries', 'filtersSelections']);
    const year = filters.get('yearSelected');
    const selectedLanguage = getState().getIn(['topFertilizerCountries', 'selectedLanguage']);
    const data = getState().getIn(['topFertilizerCountries', 'mapData', 'convertedData']);
    const translationData = getState().getIn(['topFertilizerCountries', 'translationData']).toJS().data;
    const hasUnvalidatedData = getUnvalidatedData(data);

    return hasUnvalidatedData ? [{name: getText('filter:year', props) , options: [year]},
      {name: getText('general:note', props), options:[`* ${getText('use:notValidated', {selectedLanguage, translationData})}`]}]
        : [{name: getText('filter:year', props) , options: [year]}];

  }
}

export const createExportData = (props) => {
  return (dispatch, getState) => {
    const data = getState().getIn(['topFertilizerCountries', 'mapData', 'convertedData']);
    const defaultSettings = getState().getIn(['topFertilizerCountries', 'defaultSettings', 'data']);
    const filtersSelections = getState().getIn(['topFertilizerCountries', 'filtersSelections']);
    const year = filtersSelections.get('yearSelected');
    const selectedLanguage = getState().getIn(['topFertilizerCountries', 'selectedLanguage']);
    const translationData = getState().getIn(['topFertilizerCountries', 'translationData']).toJS().data;
    let hasUnvalidatedData = getUnvalidatedData(data);
    const filters = [
      {name: getText('filter:year', props) , values: `${year}`}
    ];
    const columns = [
      {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 30},
      {headerTitle: 'Volume Imported in MT', key: 'imports', width: 30},
      {headerTitle: 'Volume Exported in MT', key: 'exports', width: 30},
      {headerTitle: 'Volume Produced in MT', key: 'production', width: 30},
      {headerTitle: 'Volume Consumed in MT', key: 'consumption', width: 30}
    ];
    const rows = [];


    data.forEach(d => {
      rows.push({
        country: d.country.name,
        imports: d.imports ? `${!d.imports.ftwgValidated ? '*' : ''}${roundNumber(d.imports.totalYear)}` : 'N/A',
        exports: d.exports ? `${!d.exports.ftwgValidated ? '*' : ''}${roundNumber(d.exports.totalYear)}` : 'N/A',
        production: d.production ? `${!d.production.ftwgValidated ? '*' : ''}${roundNumber(d.production.totalYear)}` : 'N/A',
        consumption: d.consumption ? `${!d.consumption.ftwgValidated ? '*' : ''}${roundNumber(d.consumption.totalYear)}` : 'N/A',
        cellsStyles: {
          imports: numericStyle,
          exports: numericStyle,
          production: numericStyle,
          consumption: numericStyle,
        }
      });
    })
    return {'title': defaultSettings.customChartName, filters, columns, rows,
      source: defaultSettings.source,
      analysisBy: defaultSettings.analysisBy,
      unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', {
        selectedLanguage,
        translationData
      })}` : null
    }
  }
}

const getBreaks = (data, prevRanges, unit) => {
  let values = [];
  let rangesParsed = [];
  let parsedData = data.map(d=> {
    return d.toFixed(2);
  });

  parsedData.forEach(item => {
    if (item > 0) {
      let floor = item * 0.99;
      let ceil = item * 1.01;
      if (values.indexOf(floor) === - 1) {
        values.push(floor);
      }
      if (values.indexOf(ceil) === - 1) {
        values.push(ceil);
      }
    }
  });

  if (values.length > 0) {
    const serie = new geoStats(values);
    serie.setColors(['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c']);
    serie.setPrecision(2);
    let numberOfRanges = values.length > 1 ? values.length -1: values.length;
    serie.getJenks(Math.min(5, numberOfRanges));
    serie.ranges.forEach((range) => {
      rangesParsed.push(parseFloat(range.substr(0, range.indexOf('-') - 1)));
      rangesParsed.push(parseFloat(range.substr(range.indexOf('-') + 2, range.length)));
    });
    if (rangesParsed.length > 2 && rangesParsed[0] === rangesParsed[1] && rangesParsed[0] === rangesParsed[2]) {
      rangesParsed = rangesParsed.slice(2);
    }
  }
  return rangesParsed;
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [TFC_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
    const { data, filter } = action;
    return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
  },
  [TFC_LOAD_MAP_DATA_REQUEST]: (state, action) => {
    return state.setIn(['mapData', 'loading'], true);
  },
  [TFC_LOAD_MAP_DATA_SUCCESS]: (state, action) => {
    const { data } = action;
    const convertedData = getDataConverted(data);
    return state.setIn(['mapData', 'data'], data).setIn(['mapData', 'convertedData'], convertedData)
      .setIn(['mapData', 'loading'], false).setIn(['mapData', 'loaded'], true);
  },
  [TFC_LOAD_MAP_DATA_FAILURE]: (state, action) => {
    const { err } = action;
    return state.setIn(['mapData', 'loading'], false).setIn(['loadingError'], err);
  },
  [TFC_LOAD_SETTINGS_REQUEST]: (state, action) => {
    return state.setIn(['defaultSettings', 'loading'], true);
  },
  [TFC_LOAD_SETTINGS_SUCCESS]: (state, action) => {
    const { data } = action;
    return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
  },
  [TFC_LOAD_SETTINGS_FAILURE]: (state, action) => {
    const { err } = action;
    return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
  },
  [TFC_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [TFC_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
    .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [TFC_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
  [TFC_CHANGE_FILTER_VALUE]: (state, action) => {
    const { filter, value } = action;
    return state.setIn(['filtersSelections', filter], value);
  },
  [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
    return state.setIn(['noDataText', 'loading'], true);
  },
  [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['noDataText', 'data'], data)
      .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
  },
  [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
  },

}


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  indicatorTypes:[
    { value: 'fertilizer-import', name: 'Volume Imported', dataKey: 'imports' },
    { value: 'fertilizer-export', name: 'Volume Exported', dataKey: 'exports' },
    { value: 'fertilizer-production', name: 'Volume Produced', dataKey: 'production' },
    { value: 'apparent-consumption', name: 'Volume Consumed', dataKey: 'consumption' },
  ],
  filtersData: {
    defaults: {
      loaded: false, data: []
    },
    countries: {
      loaded: false, data: []
    },
    years: {
      loaded: false, data: []
    },
  },
  filtersSelections: {
    indicator: 'fertilizer-import',
    countriesSelected: [],
    yearSelected: 0,
  },
  defaultSettings: {
    loaded: false,
    loading: false,
    data: []
  },
  bounds: {
    center: [0.189103, 37.8455004],
    zoom: 6
  },
  mapData: {
    loaded: false,
    loading: false,
    data: [],
    convertedData: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  selectedLanguage: 'en',
  translationData: {
    loaded: false, 
    loading: false, 
    data: {
      defaultLanguage: 'en',
      translations: {}
    }   
  }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
