import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';

const endpointMapping = {
  data: '/api/resource/view',
  translations: '/api/dashboardTranslation/all'
}

// ------------------------------------ Constants ----------------------------------
const RESOURCE_LOAD_DATA_REQUEST = 'RESOURCE_LOAD_DATA_REQUEST';
const RESOURCE_LOAD_DATA_SUCCESS = 'RESOURCE_LOAD_DATA_SUCCESS';
const RESOURCE_LOAD_DATA_FAILURE = 'RESOURCE_LOAD_DATA_FAILURE';
const RESOURCE_LOAD_TRANSLATION_DATA_REQUEST = 'RESOURCE_LOAD_TRANSLATION_DATA_REQUEST';
const RESOURCE_LOAD_TRANSLATION_DATA_SUCCESS = 'RESOURCE_LOAD_TRANSLATION_DATA_SUCCESS';
const RESOURCE_LOAD_TRANSLATION_DATA_FAILURE = 'RESOURCE_LOAD_TRANSLATION_DATA_FAILURE';
// ------------------------------------ Actions ------------------------------------
export const loadResource = (id) => {
  return (dispatch, getState) => {
    dispatch({'type': RESOURCE_LOAD_DATA_REQUEST});
    apiConnector.getDataWithParams(`${endpointMapping.data}?id=${id}`, {id}).then(data => {
      dispatch({'type': RESOURCE_LOAD_DATA_SUCCESS, data});
    }).catch(function (err) {
      dispatch({'type': RESOURCE_LOAD_DATA_FAILURE, err});
    })
  }
}

export const loadTranslationsData = (filter) => {
  return (dispatch, getState) => {
    dispatch({ 'type': RESOURCE_LOAD_TRANSLATION_DATA_REQUEST, filter });
    apiConnector.getData(endpointMapping.translations).then(data => {
      dispatch({ 'type': RESOURCE_LOAD_TRANSLATION_DATA_SUCCESS, data, filter });
    }).catch(function (err) {
      dispatch({ 'type': RESOURCE_LOAD_TRANSLATION_DATA_FAILURE, err, filter });
    })
  }
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [RESOURCE_LOAD_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['data', 'data'], data)
      .setIn(['data', 'loading'], false)
      .setIn(['data', 'loaded'], true);
  },
  [RESOURCE_LOAD_DATA_REQUEST]: (state, action) => {
    const {data} = action;
    return state.setIn(['data', 'loading'], true)

  },
  [RESOURCE_LOAD_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['data', 'loading'], false).setIn(['loadingError'], err);
  },
  [RESOURCE_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
    return state.setIn(['translationData', 'loading'], true);
  },
  [RESOURCE_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
    const {data} = action;
    return state.setIn(['translationData', 'data'], data)
      .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
  },
  [RESOURCE_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
    const {err} = action;
    return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
  },
}

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  data: {
    loaded: false,
    loading: false,
    data: {}
  },
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  }
})

export default function appReducer(state = initialState, action) {
  if (action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
  } else {
    return state;
  }
}