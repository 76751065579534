import Immutable from 'immutable';
import * as apiConnector from '../../api/apiConnector';
import {getText} from "../../utils/translationsUtil";
import {dispatchFilterDefaults} from "../../utils/chartUtils";

// ------------------------------------ Constants ----------------------------------
export const TEVBC_LOAD_FILTER_LIST_REQUEST = 'TEVBC_LOAD_FILTER_LIST_REQUEST';
export const TEVBC_LOAD_FILTER_LIST_SUCCESS = 'TEVBC_LOAD_FILTER_LIST_SUCCESS';
export const TEVBC_LOAD_FILTER_LIST_FAILURE = 'TEVBC_LOAD_FILTER_LIST_FAILURE';

export const TEVBC_LOAD_CHART_DATA_REQUEST = 'TEVBC_LOAD_CHART_DATA_REQUEST';
export const TEVBC_LOAD_CHART_DATA_SUCCESS = 'TEVBC_LOAD_CHART_DATA_SUCCESS';
export const TEVBC_LOAD_CHART_DATA_FAILURE = 'TEVBC_LOAD_CHART_DATA_FAILURE';

export const TEVBC_LOAD_SETTINGS_REQUEST = 'TEVBC_LOAD_SETTINGS_REQUEST';
export const TEVBC_LOAD_SETTINGS_SUCCESS = 'TEVBC_LOAD_SETTINGS_SUCCESS';
export const TEVBC_LOAD_SETTINGS_FAILURE = 'TEVBC_LOAD_SETTINGS_FAILURE';

export const TEVBC_LOAD_TRANSLATION_DATA_REQUEST = 'TEVBC_LOAD_TRANSLATION_DATA_REQUEST';
export const TEVBC_LOAD_TRANSLATION_DATA_SUCCESS = 'TEVBC_LOAD_TRANSLATION_DATA_SUCCESS';
export const TEVBC_LOAD_TRANSLATION_DATA_FAILURE = 'TEVBC_LOAD_TRANSLATION_DATA_FAILURE';

export const TEVBC_CHANGE_FILTER_VALUE = 'TEVBC_CHANGE_FILTER_VALUE';

const LOAD_NO_DATA_TEXT_REQUEST = 'TEVBC_LOAD_NO_DATA_TEXT_REQUEST';
const LOAD_NO_DATA_TEXT_SUCCESS = 'TEVBC_LOAD_NO_DATA_TEXT_SUCCESS';
const LOAD_NO_DATA_TEXT_FAILURE = 'TEVBC_LOAD_NO_DATA_TEXT_FAILURE';

const endpointMapping = {
    filters: {
        years: '/api/exports/regional/yearsList',
        countries: '/api/exports/regional/totalExportVolumesByCountry/countries',
        defaults: '/api/filtersDefaults/exports/regional/totalExportVolumesByCountry'
    },
    chartData: '/api/exports/regional/totalExportVolumesByCountry',
    settings: '/api/configuration/totalExportVolumesByCountry',
    noDataText: '/api/settings/noData',
    translations: '/api/dashboardTranslation/all'
}

// ------------------------------------ Actions ------------------------------------

export const loadNoDataText = () => {
    return (dispatch, getState) => {
        dispatch({ 'type': LOAD_NO_DATA_TEXT_REQUEST });
        apiConnector.getData(endpointMapping.noDataText).then(data => {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_SUCCESS, data});
        }).catch(function(err) {
            dispatch({ 'type': LOAD_NO_DATA_TEXT_FAILURE, err});
        })
    }
}

export const loadTranslationsData = (filter) => {
    return (dispatch, getState) => {
        dispatch({'type': TEVBC_LOAD_TRANSLATION_DATA_REQUEST, filter});
        apiConnector.getData(endpointMapping.translations).then(data => {
            dispatch({'type': TEVBC_LOAD_TRANSLATION_DATA_SUCCESS, data, filter});
        }).catch(function (err) {
            dispatch({'type': TEVBC_LOAD_TRANSLATION_DATA_FAILURE, err, filter});
        })
    }
}

export const loadFilterData = (filter) => {
    return (dispatch, getState) => {
        dispatch({'type': TEVBC_LOAD_FILTER_LIST_REQUEST, filter});
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.filters[filter]}?selectedLanguage=${lang}`).then(data => {
            dispatch({'type': TEVBC_LOAD_FILTER_LIST_SUCCESS, data, filter});
        }).catch(function (err) {
            dispatch({'type': TEVBC_LOAD_FILTER_LIST_FAILURE, err, filter});
        })
    }
}

export const loadFilterDataIfNotLoaded = (filter) => {
    return (dispatch, getState) => {
        if (!getState().getIn(['totalExportVolumesByCountry', 'filtersData', filter, 'loaded'])) {
            dispatch(loadFilterData(filter));
        }
    }
}

export const loadChartData = () => {
    return (dispatch, getState) => {
        dispatch({'type': TEVBC_LOAD_CHART_DATA_REQUEST});
        const params = getState().getIn(['totalExportVolumesByCountry', 'filtersSelections']).toJS();
        params.lang = getState().getIn(['intl', 'locale']);
        apiConnector.getDataWithParams(endpointMapping.chartData, params).then(data => {
            dispatch({'type': TEVBC_LOAD_CHART_DATA_SUCCESS, data});
        }).catch(function (err) {
            dispatch({'type': TEVBC_LOAD_CHART_DATA_FAILURE, err});
        })
    }
}

export const loadDefaultSettings = () => {
    return (dispatch, getState) => {
        dispatch({'type': TEVBC_LOAD_SETTINGS_REQUEST});
        const lang = getState().getIn(['intl', 'locale']);
        apiConnector.getData(`${endpointMapping.settings}?selectedLanguage=${lang}`).then(data => {
            dispatch(setDefaultFilters(data));
            dispatch({'type': TEVBC_LOAD_SETTINGS_SUCCESS, data});
        }).catch(function (err) {
            dispatch({'type': TEVBC_LOAD_SETTINGS_FAILURE, err});
        })
    }
}

export const resetFilters = (chart) => {
    return (dispatch, getState) => {
        dispatch(setDefaultFilters(getState().getIn(['totalExportVolumesByCountry', 'defaultSettings', 'data'])));
        dispatch(loadChartData());
    }
}

const setDefaultFilters = (settings) => {
    return (dispatch, getState) => {
        dispatchFilterDefaults(changeFilterValue, dispatch, getState, 'totalExportVolumesByCountry', 'countriesSelected', 'years');
    }
}


export const changeFilterValue = (filter, value, reload) => {
    return (dispatch, getState) => {
        dispatch({'type': TEVBC_CHANGE_FILTER_VALUE, filter, value});
        if (reload) {
            dispatch(loadChartData());
        }
    }
}


export const getFiltersForPrint = (props) => {
    return (dispatch, getState) => {
        const years = getState().getIn(['totalExportVolumesByCountry', 'filtersSelections', 'years']);
        const importData = getState().getIn(['totalExportVolumesByCountry', 'chartData', 'data']);
        const selectedLanguage = getState().getIn(['totalExportVolumesByCountry', 'selectedLanguage']);
        const translationData = getState().getIn(['totalExportVolumesByCountry', 'translationData']).toJS().data;
        const retValue = [{name: getText('filter:yearS', {selectedLanguage, translationData}), options: years}];
        let hasUnvalidatedData = importData.filter(d => d.countryVolume.find(cv => cv.ftwgValidated === false)).length > 0;
        if (hasUnvalidatedData) {
            retValue.unshift({name: getText('general:note', props), options:[`* ${getText('use:notValidated', props)}`], isSettings: true})
        }
        return retValue;
    }
}

export const createExportData = (props) => {
    return (dispatch, getState) => {
        const importFilters = getState().getIn(['totalExportVolumesByCountry', 'filtersSelections']);
        const importData = getState().getIn(['totalExportVolumesByCountry', 'chartData', 'data']);
        const defaultSettings = getState().getIn(['totalExportVolumesByCountry', 'defaultSettings', 'data']);
        const years = getState().getIn(['totalExportVolumesByCountry', 'filtersData', 'years', 'data']);
        const yearList = years.filter(i => importFilters.get('years').indexOf(i) !== -1);
        const countries = getState().getIn(['totalExportVolumesByCountry', 'filtersData', 'countries', 'data']);
        const countriesList = countries.filter(i => importFilters.get('countriesSelected').indexOf(i.id) !== -1).map(i => i.name);
        const filters = [
            {name: getText('filter:year', props), values: yearList.join(', ')},
            {name: getText('general:countries', props), values: countriesList.join(', ')},
        ];

        const columns = [
            {headerTitle: getText('plantDirectory:country', props), key: 'country', width: 40},
            {headerTitle: getText('filter:year', props), key: 'year', width: 20},
            {headerTitle: getText('availability:totalExportedInMt', props), key: 'imports', width: 35}
        ];

        const rows = [];
        let hasUnvalidatedData = false;
        importData.forEach(d => {
            const {year, countryVolume} = d;
            countryVolume.forEach(p => {
                if (p.ftwgValidated === false) {
                    hasUnvalidatedData = true;
                }
                    rows.push({
                        country: p.country.name,
                        year: `${year}${p.ftwgValidated ? '' : '*'}`,
                        imports: p.volume,
                        cellsStyles: {
                            imports: {
                                alignment: {vertical: 'middle', horizontal: 'right'},
                                numFmt: '#,##0.00'
                            }
                        }
                    });
                })
        })

        return {
            'title': defaultSettings.customChartName, filters, columns, rows,
            source: defaultSettings.source,
            analysisBy: defaultSettings.analysisBy,
            unvalidatedDataMsg: hasUnvalidatedData ? `* ${getText('use:notValidated', props)}` : null
        }
    }
}
// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
    [TEVBC_LOAD_FILTER_LIST_SUCCESS]: (state, action) => {
        const {data, filter} = action;
        return state.setIn(['filtersData', filter, 'loaded'], true).setIn(['filtersData', filter, 'data'], data);
    },
    [TEVBC_LOAD_FILTER_LIST_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['loadingError'], err);
    },
    [TEVBC_LOAD_CHART_DATA_REQUEST]: (state, action) => {
        return state.setIn(['chartData', 'loading'], true);
    },
    [TEVBC_LOAD_CHART_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['chartData', 'data'], data)
            .setIn(['chartData', 'loading'], false).setIn(['chartData', 'loaded'], true);
    },
    [TEVBC_LOAD_CHART_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['chartData', 'loading'], false).setIn(['loadingError'], err);
    },
    [TEVBC_LOAD_SETTINGS_REQUEST]: (state, action) => {
        return state.setIn(['defaultSettings', 'loading'], true);
    },
    [TEVBC_LOAD_SETTINGS_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['defaultSettings', 'loaded'], true).setIn(['defaultSettings', 'data'], data);
    },
    [TEVBC_LOAD_SETTINGS_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['defaultSettings', 'loading'], false).setIn(['loadingError'], err);
    },
    [TEVBC_CHANGE_FILTER_VALUE]: (state, action) => {
        const {filter, value} = action;
        return state.setIn(['filtersSelections', filter], value);
    },
    [TEVBC_LOAD_TRANSLATION_DATA_REQUEST]: (state, action) => {
        return state.setIn(['translationData', 'loading'], true);
    },
    [TEVBC_LOAD_TRANSLATION_DATA_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['translationData', 'data'], data)
            .setIn(['translationData', 'loading'], false).setIn(['translationData', 'loaded'], true);
    },
    [TEVBC_LOAD_TRANSLATION_DATA_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['translationData', 'loading'], false).setIn(['loadingError'], err);
    },
    [LOAD_NO_DATA_TEXT_REQUEST]: (state, action) => {
        return state.setIn(['noDataText', 'loading'], true);
    },
    [LOAD_NO_DATA_TEXT_SUCCESS]: (state, action) => {
        const {data} = action;
        return state.setIn(['noDataText', 'data'], data)
          .setIn(['noDataText', 'loading'], false).setIn(['noDataText', 'loaded'], true);
    },
    [LOAD_NO_DATA_TEXT_FAILURE]: (state, action) => {
        const {err} = action;
        return state.setIn(['noDataText', 'loading'], false).setIn(['loadingError'], err);
    },

};


// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
    filtersData: {
        defaults: {
            loaded: false, data: []
        },
        dates: {
            loaded: false, data: []
        },
        years: {
            loaded: false, data: []
        },
        countries: {
            loaded: false, data: []
        },
    },
    filtersSelections: {
        years: [],
        countriesSelected: []
    },
    defaultSettings: {
        loaded: false,
        loading: false,
        data: {}
    },
    noDataText: {
        loaded: false,
        loading: false,
        data: {}
    },
    translationData: {
        loaded: false,
        loading: false,
        data: {
            defaultLanguage: 'en',
            translations: {}
        }
    },
    chartData: {
        loaded: false,
        loading: false,
        data: [], convertedData: {}
    }
})

// reducer is returned as default
export default function appReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
}
