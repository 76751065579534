
export const formatMonthlyDate = (date) => {  
    if (date) {
      date.setDate(date.getDate() + 1);
      return date.toLocaleString('en-GB', { month: 'long' }).replace(/ /g,'-');
    } else {
      return'unset date';
    }
}

export const formatMonthYear = (date) => {
    if (date) {
        date.setDate(date.getDate() + 1);
        return date.toLocaleString('en-GB', { month: 'long', year: 'numeric' });
    } else {
        return'unset date';
    }
}

export const formatDate = (date) => {  
    return `${date.toLocaleString('en-US', {'year': 'numeric'})}-${date.toLocaleString('en-US', {'month': '2-digit'})}-${date.toLocaleString('en-US', {'day': '2-digit'})}`
}

export const getLastDayOfMonth = (date) => {  
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
}

export const getMonthsBetweenDates = (dates) => {
	if (dates.length !== 2) {
		return [];
	}
	const startMonth = parseInt(dates[0].split('-')[1]);
    const startYear = parseInt(dates[0].split('-')[0]);
    const endMonth = parseInt(dates[1].split('-')[1]);
    const endYear = parseInt(dates[1].split('-')[0]);
    const months = [];
    let monthIndex = startMonth;
    let yearIndex = startYear;
    const endMonthYear = `${getMonthsShort('en')[endMonth - 1]} ${endYear}`;
    let monthYear = '';
    while (monthYear !== endMonthYear) {
    	monthYear = `${getMonthsShort('en')[monthIndex - 1]} ${yearIndex}`;
    	months.push(monthYear);
    	monthIndex += 1;
    	if (monthIndex > 12) {
    		monthIndex = 1;
    		yearIndex += 1;
    	}
    }
    return months;
}

export const getMonthsLong = (selectedLanguage) => {
  const months = [];
  for (let mn = 0; mn < 12; mn++) {
    months.push(new Date(new Date().setMonth(mn)).toLocaleString(selectedLanguage, { month: 'long' }));
  }
  return months;
}

export const getMonthsShort = (selectedLanguage) => {
  const months = [];
  for (let mn = 0; mn < 12; mn++) {
    months.push(new Date(new Date().setMonth(mn)).toLocaleString(selectedLanguage, { month: 'short' }));
  }
  return months;
}

export const getQuarterMonths = (quarter, selectedLanguage) => {
  let startMonth, endMonth = '';
  switch (quarter) {
    case 'Q1':
      startMonth = new Date(new Date().setMonth(0)).toLocaleString(selectedLanguage, { month: 'short' });
      endMonth = new Date(new Date().setMonth(2)).toLocaleString(selectedLanguage, { month: 'short' });
      return `Q1 - ${startMonth} - ${endMonth}`;
      break;
    case 'Q2':
      startMonth = new Date(new Date().setMonth(3)).toLocaleString(selectedLanguage, { month: 'short' });
      endMonth = new Date(new Date().setMonth(5)).toLocaleString(selectedLanguage, { month: 'short' });
      return `Q2 - ${startMonth} - ${endMonth}`;
      break;
    case 'Q3':
      startMonth = new Date(new Date().setMonth(6)).toLocaleString(selectedLanguage, { month: 'short' });
      endMonth = new Date(new Date().setMonth(8)).toLocaleString(selectedLanguage, { month: 'short' });
      return `Q3 - ${startMonth} - ${endMonth}`;
      break;
    case 'Q4':
      startMonth = new Date(new Date().setMonth(9)).toLocaleString(selectedLanguage, { month: 'short' });
      endMonth = new Date(new Date().setMonth(11)).toLocaleString(selectedLanguage, { month: 'short' });
      return `Q4 - ${startMonth} - ${endMonth}`;
      break;
  }
}